import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    useTheme,
} from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import "./AlarmProfile.css";

interface Props {
    profileList?: any[];
    profileDefs: { [key: string]: { headline: string } };
    selectedProfile: string;
    setProfileDef: (p: any) => void;
    setProfileSelect: (p: any) => void;
    title: string;
    sx?: object; // Add sx property to the Props interface
}

export const AlarmProfileSelect = ({
    profileDefs,
    setProfileDef,
    setProfileSelect,
    selectedProfile,
    title,
    sx,
}: Props) => {
    const theme = useTheme();
    const alarmProfileDefs: { [key: string]: { headline: string } } = profileDefs;

    const plist: [string, string][] = [];

    for (var key in alarmProfileDefs) {
        if (alarmProfileDefs.hasOwnProperty(key)) {
            plist.push([key, alarmProfileDefs[key].headline]);
        }
    }

    const lookup_alarmProfileDef = (value: string) => {
        return alarmProfileDefs[value];
    }

    const { t } = useTranslation();

    const handleAlarmProfileChange = (event: SelectChangeEvent) => {
        setProfileSelect(event.target.value);
        const key = event.target.value;
        const profileDef = lookup_alarmProfileDef(key);
        setProfileDef(profileDef);
    };

    return (
        <div>
            <FormControl fullWidth sx={sx}>
                <InputLabel sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper,
                        paddingLeft: 1,
                        paddingRight: 1,
                    }}
                    shrink={true}
                >
                    {t(title)}
                </InputLabel>
                <Select
                    value={selectedProfile}
                    label={t(title)}
                    onChange={handleAlarmProfileChange}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.background.paper, // Set the background color
                        '& .MuiInputLabel-root.Mui-focused': {
                            backgroundColor: theme.palette.background.paper, // Ensure the label background matches the form background
                        },
                    }}
                >
                    {plist.map((ap) => (
                        <MenuItem key={ap[0]} value={ap[0]}>
                            {ap[1]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export const AlarmProfileSelectMemo = memo(AlarmProfileSelect);
