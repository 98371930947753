import { useState, useEffect, useRef } from "react";
import {
    useTheme,
    Grid,
    Box,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { RoleType, User } from "../../interfaces/user/User";
import { isBrowser } from "react-device-detect";
import UserMgmtDialog from "./UserMgmtDialog";
import GenericDropdown from "../DeviceManagement/GenericDropdown";

export interface OrgEntry {
    organization_id: string;
    organization_name: string;
    organization_type: string;
}

export const UserManagement = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showUserDialog, setShowUserDialog] = useState(false); // Show new user input dialog
    const [showOK, setShowOK] = useState(false); // Show OK dialog on user add success
    const dialogMode = useRef<string>("add");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    var token = cookies.access_token; // userCtx.user.token;
    var [users, setUsers] = useState<User[]>([]);
    const [userLevels, setUserLevels] = useState<RoleType[]>([]);
    const [organizations, setOrganizations] = useState<OrgEntry[]>([]);
    const [filterText, setFilterText] = useState('');
    const [selectedUser, setSelectedUser] = useState<User>();
    const [fetchUsers, setFetchUsers] = useState(0);
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");

    const handleRowClick = (user: any) => {
        dialogMode.current = "edit";
        if (user.phone === undefined) {
            user.phone = '';
        }
        setSelectedUser(user);
        setShowUserDialog(true);
    };

    const handleCloseDialog = () => {
        setShowUserDialog(false);
    };

    const triggerRefresh = () => {
        setFetchUsers(fetchUsers + 1);
    };

    const sortUsersByLastName = (users: User[]) => {
        return [...users].sort((a, b) => {
            const lastNameA = a.last_name || "";
            const lastNameB = b.last_name || "";
            return lastNameA.localeCompare(lastNameB, undefined, { sensitivity: 'base' });
        });
    };

    useEffect(() => {
        let isMounted = true;

        UserService.getAllHierarchyUsers(token).then((response: any) => {
            if (isMounted) {
                const sortedUsers = sortUsersByLastName(response.users);
                setUsers(sortedUsers);
            }
        }).catch((error: any) => {
            console.log("Fetch error response:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [fetchUsers, token]);

    useEffect(() => {
        let isMounted = true;

        UserService.getMyProfile(token).then((response: any) => {
            if (isMounted) {
                console.log("my profile:", response);
                setUserLevels(response.userLevels);
                setIsSuperAdmin(response.myprofile.is_superadmin);

                const orgData = response.organizations.map((org: OrgEntry) => ({
                    organization_id: org.organization_id,
                    organization_name: org.organization_name,
                    organization_type: org.organization_type
                }));
                setOrganizations(orgData);
            }
        }).catch((error: any) => {
            console.log("Fetch error response:", error);
        });

        return () => {
            isMounted = false;
        };
    }, [token]);

    const addUserButton = () => {
        dialogMode.current = "add";
        setShowUserDialog(true);
    };

    const handleCloseOK = (event: React.SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== "backdropClick") {
            setShowUserDialog(false);
            setShowOK(false);
        }
    };

    const fontVariant = isBrowser ? "body2" : "caption";

    const handleOrganizationChange = (value: string) => {
        setSelectedOrganization(value);
    };

    const filteredUsers = users.filter(user => {
        const searchText = filterText.toLowerCase();
        const organizationMatch = selectedOrganization === "" || user.organization === selectedOrganization;
        return organizationMatch && (
            user.last_name?.toLowerCase().includes(searchText) ||
            user.first_name?.toLowerCase().includes(searchText) ||
            user.username?.toLowerCase().includes(searchText) ||
            user.email?.toLowerCase().includes(searchText) ||
            user.phone?.toLowerCase().includes(searchText) ||
            (user.is_admin ? 'Yes' : 'No').toLowerCase().includes(searchText)
        );
    });

    const sortedOrganizations = [...organizations].sort((a, b) => 
        a.organization_name.toLowerCase().localeCompare(b.organization_name.toLowerCase())
    );
    const organizationOptions = [
        { value: "", label: t("All") },
        ...sortedOrganizations.map(org => ({
            value: org.organization_id,
            label: org.organization_name,
        }))
    ];

    return (
        <Box sx={{ mx: 2, mt: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <TextField
                        label={t("Search")}
                        variant="outlined"
                        value={filterText}
                        InputLabelProps={{ shrink: true }}
                        placeholder={t("type_to_filter")}
                        onChange={(e) => setFilterText(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h4" align="center">
                        {t("user_management")}
                    </Typography>
                </Grid>
                {isSuperAdmin && (
                    <Grid item>
                        <GenericDropdown
                            options={organizationOptions}
                            selectedValue={selectedOrganization}
                            onChange={handleOrganizationChange}
                            title={t("customer_org")}
                            sx={{ minWidth: 200 }}
                        />
                    </Grid>
                )}
            </Grid>

            <Dialog open={showOK} fullWidth={true} maxWidth={"md"}>
                <DialogTitle> {t("success")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("user_added_successfully")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseOK}> {t("close")}</Button>
                </DialogActions>
            </Dialog>

            <UserMgmtDialog
                userData={selectedUser}
                open={showUserDialog}
                handleClose={handleCloseDialog}
                userLevels={userLevels}
                dialogMode={dialogMode.current}
                organizations={organizations}
                triggerRefresh={triggerRefresh}
                is_superadmin={isSuperAdmin}
            />

            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("lastname")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("firstname")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("username")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("email_address")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("phone")}</TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>{t("role")}</TableCell>
                                {isSuperAdmin && <TableCell sx={{ padding: "16px 6px" }}>{t("Customer Org")}</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map((user: User) => (
                                <TableRow
                                    key={user.username}
                                    hover
                                    onClick={() => handleRowClick(user)}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        "&:nth-of-type(odd)": { background: theme.palette.info.dark },
                                    }}
                                >
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.last_name}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.first_name}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.username}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.email}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.phone}</Typography>
                                    </TableCell>

                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{t(user.role?.name || '')}</Typography>
                                    </TableCell>

                                    {isSuperAdmin && <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.organization_name}</Typography>
                                    </TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid container mt={2}>
                <Grid item xs={6} md={4}>
                    <Button variant="outlined" onClick={addUserButton}>
                        {t("add_user")}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};
