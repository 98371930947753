import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { DeviceTable } from "../../pages/Home/DeviceTable";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { useState, useEffect, useContext } from 'react';
import { Grid, Box, TextField, Typography } from '@mui/material';
import { DeviceMgmtDialog } from "./DeviceMgmtDialog";
import { UserContext } from "../../context/UserContext";
import { Device } from "../../interfaces/Device";
import GenericDropdown from "./GenericDropdown";
import { OrgEntry } from "../UserMgmt/UserManagement";

export const DeviceManagement = () => {
    const { t } = useTranslation();
    const userCtx = useContext(UserContext);
    const [cookies, ,] = useCookies(["access_token"]);
    var token = cookies.access_token;
    const [showDialog, setShowDialog] = useState(false);
    const [deviceList, setDeviceList] = useState<Device[]>([]);
    const [filterText, setFilterText] = useState('');
    const [organizations, setOrganizations] = useState<OrgEntry[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string>("");
    const [showOrgDropdown, setShowOrgDropdown] = useState(false);

    function insertDeviceInSortedArray(devices: Device[], newDevice: Device): Device[] {
        const insertIndex = devices.findIndex(device => device.device_id > newDevice.device_id);
        if (insertIndex === -1) {
            devices.push(newDevice); // Add to the end if no larger id is found
        } else {
            devices.splice(insertIndex, 0, newDevice); // Insert at the correct index
        }
        return devices;
    }

    const handleOrganizationChange = (value: string) => {
        setSelectedOrganization(value);
    };

    const addDevice = (device: Device) => {
        insertDeviceInSortedArray(deviceList, device);
        setDeviceList([...deviceList]);
    };

    const removeDevice = (deviceIdToRemove: string) => {
        const updatedList = deviceList.filter(device => device.device_id !== deviceIdToRemove);
        setDeviceList(updatedList);
    };

    const updateDevice = (updatedDevice: Device) => {
        const deviceIndex = deviceList.findIndex(device => device.device_id === updatedDevice.device_id);
        if (deviceIndex !== -1) {
            deviceList[deviceIndex] = { ...deviceList[deviceIndex], ...updatedDevice };
            setDeviceList([...deviceList]);
        }
    };

    const sortedOrganizations = [...organizations].sort((a, b) =>
        a.organization_name.toLowerCase().localeCompare(b.organization_name.toLowerCase())
    );

    const organizationOptions = [
        { value: "", label: t("All") },
        ...sortedOrganizations.map(org => ({
            value: org.organization_id,
            label: org.organization_name,
        }))
    ];

    const {
        data,
        loading: deviceLoading,
        error: deviceError,
    } = useFetch({
        url: DeviceService.getDevices(true),
        access_token: token
    });

    const {
        data: alarmProfiles,
        loading: almLoading,
        error: almError
    } = useFetch({
        url: DeviceService.getTypes(),
        access_token: token
    });

    useEffect(() => {
        let isMounted = true;
        if (data && isMounted) {
            setDeviceList(data);
        }
        return () => {
            isMounted = false;
        };
    }, [data]);

    useEffect(() => {
        if (!userCtx?.user?.role) {
            return;
        }
        if (userCtx?.user?.role?.permission_level > 700) {
            setShowOrgDropdown(true);
        } else {
            setShowOrgDropdown(false);
        }
    }, [userCtx?.user?.role]);

    useEffect(() => {
        let isMounted = true;
        UserService.getMyProfile(token).then((response: any) => {
            if (isMounted) {
                const orgData = response.organizations.map((org: OrgEntry) => ({
                    organization_id: org.organization_id,
                    organization_name: org.organization_name,
                    organization_type: org.organization_type
                }));
                setOrganizations(orgData);
            }
        })
            .catch((error: any) => {
                console.log("Fetch error response:", error);
            })
            .finally(() => {});

        return () => {
            isMounted = false;
        };
    }, [token]);

    if (almLoading || deviceLoading || !alarmProfiles) {
        return <Loading />
    }
    if (almError || deviceError) {
        return <ServerProblem />
    }

    const filteredDeviceList = deviceList.filter(device => {
        return (selectedOrganization === "" || device.owner_organization === selectedOrganization) &&
               (device.device_id.toLowerCase().includes(filterText.toLowerCase()) ||
                device.case_id?.toLowerCase().includes(filterText.toLowerCase()) ||
                device.device_type?.toLowerCase().includes(filterText.toLowerCase()) ||
                device.device_serialnumber?.toLowerCase().includes(filterText.toLowerCase()) ||
                device.device_model?.toLowerCase().includes(filterText.toLowerCase()) ||
                device.nickname?.toLowerCase().includes(filterText.toLowerCase()));
    });

    return (
        <Box sx={{ mx: 2, mt: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <TextField
                        label={t("Search")}
                        variant="outlined"
                        value={filterText}
                        InputLabelProps={{ shrink: true }}
                        placeholder={t("type_to_filter")}
                        onChange={(e) => setFilterText(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h4" align="center">
                        {t("device_management")}
                    </Typography>
                </Grid>
                {showOrgDropdown && <Grid item>
                    <GenericDropdown
                        options={organizationOptions}
                        selectedValue={selectedOrganization}
                        onChange={handleOrganizationChange}
                        title={t("customer_org")}
                        sx={{ minWidth: 200 }}
                    />
                </Grid>
                }
            </Grid>

            <DeviceTable
                devicelist={filteredDeviceList}
                alarmProp={alarmProfiles}
                removeDevice={removeDevice}
                updateDevice={updateDevice}
                organizationOptions={organizationOptions} // Pass organizationOptions here
            />

            <DeviceMgmtDialog
                action={"add"}
                openState={showDialog}
                setOpenState={setShowDialog}
                alarmProp={alarmProfiles}
                deviceAction={addDevice}
            />
        </Box>
    );
};
