import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, ListItem, ListItemText, IconButton, Typography,  } from '@mui/material';
import { PoolDevice } from './types';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";

interface AssignDeviceDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (assignedDevices: PoolDevice[]) => void;
    availableDevices: PoolDevice[];
    assignedDevices: PoolDevice[];
    licensePool: number;
}

const AssignDeviceDialog: React.FC<AssignDeviceDialogProps> = ({ open, onClose, onSave, availableDevices, assignedDevices, licensePool }) => {
    const [assigned, setAssigned] = useState<PoolDevice[]>([]);
    const [available, setAvailable] = useState<PoolDevice[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [maxDevicesReached, setMaxDevicesReached] = useState<boolean>(false);
    const { t } = useTranslation();
    //const theme = useTheme();

    useEffect(() => {
        setAvailable(sortDevices(availableDevices));
    }, [availableDevices]);

    useEffect(() => {
        setAssigned(sortDevices(assignedDevices));
    }, [assignedDevices]);

    useEffect(() => {
        setMaxDevicesReached(false);
    }, [open, assignedDevices]);

    const sortDevices = (devices: PoolDevice[]): PoolDevice[] => {
        return devices.slice().sort((a, b) => a.device_serialnumber.toLowerCase().localeCompare(b.device_serialnumber.toLowerCase()));
    };

    const handleAssign = (device: PoolDevice) => {
        if (assigned.length < licensePool) {
            const newAssigned = sortDevices([...assigned, device]);
            const newAvailable = sortDevices(available.filter(d => d.device_id !== device.device_id));
            setAssigned(newAssigned);
            setAvailable(newAvailable);
            setMaxDevicesReached(false);
        } else {
            setMaxDevicesReached(true);
        }
    };

    const handleRemove = (device: PoolDevice) => {
        const newAssigned = sortDevices(assigned.filter(d => d.device_id !== device.device_id));
        const newAvailable = sortDevices([...available, device]);
        setAssigned(newAssigned);
        setAvailable(newAvailable);
        setMaxDevicesReached(false);
    };

    const handleSelectAll = (isAssign: boolean) => {
        if (isAssign) {
            if (assigned.length + available.length <= licensePool) {
                setAssigned(sortDevices([...assigned, ...available]));
                setAvailable([]);
                setMaxDevicesReached(false);
            } else {
                setMaxDevicesReached(true);
            }
        } else {
            setAvailable(sortDevices([...available, ...assigned]));
            setAssigned([]);
            setMaxDevicesReached(false);
        }
    };

    const filterDevices = (devices: PoolDevice[], searchTerm: string) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        return devices.filter(device =>
            device.device_serialnumber.toLowerCase().includes(lowercasedTerm) ||
            device.deviceType.toLowerCase().includes(lowercasedTerm) ||
            device.hierarchyNode.toLowerCase().includes(lowercasedTerm)
        );
    };

    const filteredAvailable = filterDevices(available, searchTerm);
    const filteredAssigned = filterDevices(assigned, searchTerm);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Assign Devices
                <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    placeholder={ t("search_devices") }
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                    onChange={e => setSearchTerm(e.target.value)}
                />

                <div style={{ display: 'flex', height: 400 }}>
                    <div style={{ flex: 1, marginRight: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">{ t("available_devices") }</Typography>
                            <Button onClick={() => handleSelectAll(true)}>Select All</Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                            <Typography variant="subtitle1">{ t("serial_number") }</Typography>
                            <Typography variant="subtitle1">{ t("device_type") }</Typography>
                        </div>
                        <List style={{ maxHeight: 300, overflow: 'auto' }}>
                            {filteredAvailable.map((device, index) => (
                                <ListItem
                                    key={device.device_id}
                                    button
                                    onClick={() => handleAssign(device)}
                                    style={{
                                        padding: '4px 16px',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>{device.device_serialnumber}</span>
                                                <span>{device.deviceType}</span>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                    <div style={{ width: '2px', backgroundColor: 'grey', margin: '0 10px' }}></div>
                    <div style={{ flex: 1, marginLeft: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">{ t("assigned_devices") }</Typography>
                            <Button onClick={() => handleSelectAll(false)}>Deselect All</Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                            <Typography variant="subtitle1">{ t("serial_number") }</Typography>
                            <Typography variant="subtitle1">{ t("device_type") }</Typography>
                        </div>
                        <List style={{ maxHeight: 300, overflow: 'auto' }}>
                            {filteredAssigned.map((device, index) => (
                                <ListItem
                                    key={device.device_id}
                                    button
                                    onClick={() => handleRemove(device)}
                                    style={{
                                        padding: '4px 16px',
                                    }}
                                >
                                    <ListItemText
                                        primary={
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <span>{device.device_serialnumber}</span>
                                                <span>{device.deviceType}</span>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {maxDevicesReached && (
                    <Typography variant="body2" color="error" style={{ flex: 1 }}>
                        { t("max_devices_reached") }
                    </Typography>
                )}
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button onClick={() => onSave(assigned)} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AssignDeviceDialog;
