import { useTheme } from "@mui/material";

export const GetColorNEWS = (score: number | null | undefined) => {
    const theme = useTheme();
    if (score) {
        if (score >= 0 && score < 3) return "initial";
        else if (score > 2 && score < 5) return theme.palette.error.light;
        else if (score > 4 && score < 7) return theme.palette.error.dark;
        return theme.palette.error.main;
    }
    return "initial";
};
