import {
    //    FormControl,
    //    FormControlLabel,
    //    FormGroup,
    Grid,
    //    Switch,
} from "@mui/material";
import { Device } from "../../interfaces/Device";
import TrendUnit from "./Trend/TrendUnit";
import { useState, useEffect } from "react";
import { Loading } from "../../components/Loading";
import { ReactNode } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
    devices: Device[];
    children: ReactNode;
}
export const TrendDashboard = ({ devices, children }: Props) => {
    //console.log ("TrendDashboard renders", devices)

    const [leftPatients, setLeftPatients] = useState<Device[]>();
    const [rightPatients, setRightPatients] = useState<Device[]>();
    // devices.filter((_device, index) => !(index % 2))
    const [isTrendHidden] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (devices == null) {
            return;
        }
        setLeftPatients(devices.filter((_device, index) => index % 2));
        setRightPatients(devices.filter((_device, index) => !(index % 2)));
    }, [devices]);

    if (devices == null) {
        return <Loading />;
    }
    if (!leftPatients || !rightPatients) {
        return <Loading />;
    }
    //console.log ("leftPatients:", leftPatients)
    //console.log ("rightPatients:", rightPatients)
    return (
        <>
            { children }
            { devices?.length > 0 ? <Grid container direction="row" justifyContent="space-between">
                {devices?.map((device) => (
                    <TrendUnit
                        key={device.device_id}
                        device={device}
                        hideTrend={isTrendHidden}
                        type="trend"
                        useLink={true}
                        connectStatus="undefined"
                    />
                ))} 
            </Grid> : <Typography>{ t("no_devices_to_show") }</Typography>}
        </>
    );
};
