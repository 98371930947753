import React, { useState, useEffect } from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    TextField,
    Grid,
    Typography,
} from "@mui/material";

import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import { Loading } from "../../components/Loading";
import { Device } from "../../interfaces/Device";
import { useTranslation } from "react-i18next";
import { DeviceMgmtDialog } from "../DeviceManagement/DeviceMgmtDialog";
import { isBrowser } from "react-device-detect";

interface Props {
    devicelist: Device[];
    alarmProp: any;
    removeDevice: (device_id: string) => void;
    updateDevice: (device: Device) => void;
    organizationOptions: { value: string, label: string }[]; // Add organizationOptions prop
}

export const DeviceTable = ({ devicelist, alarmProp, removeDevice, updateDevice, organizationOptions }: Props) => {
    const [device, setDevice] = useState<Device | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [rows, setRows] = useState<Device[]>([]);
    const [searched, setSearched] = useState<string>("");
    const { t } = useTranslation();
    const theme = useTheme();
    const fontVariant = isBrowser ? "body2" : "caption";

    useEffect(() => {
        if (!showDialog) {
            setDevice(null);
        }
    }, [showDialog]);

    useEffect(() => {
        setRows(devicelist);
    }, [devicelist]);

    if (!devicelist || !alarmProp) {
        return <Loading />;
    }

    const editDevice = (device: Device, action?: string) => {
        if (action === "delete") {
            removeDevice(device.device_id);
        } else if (action === "edit") {
            updateDevice(device);
        }
    };

    const requestSearch = (searchedVal: string) => {
        const filteredRows = devicelist.filter((row) => {
            const searchstring: string = row.device_id + row.device_type + row.device_model + row.location + row.nickname;
            return searchstring.toLowerCase().includes(searchedVal.trim().toLowerCase());
        });
        setRows(filteredRows);
    };

    const doSearch = (event: any) => {
        setSearched(event.target.value);
        requestSearch(event.target.value);
    };

    const getOrganizationName = (organizationId: string) => {
        const org = organizationOptions.find(option => option.value === organizationId);
        return org ? org.label : "";
    };

    return (
        <Grid container>
            { false && <TextField
                id="outlined-basic"
                label={t("Search")}
                variant="outlined"
                value={searched}
                onChange={doSearch}
                InputLabelProps={{ shrink: true }}
                placeholder={t("type_to_search")}
                sx={{
                    input: {
                        color: theme.palette.text.primary,
                        background: theme.palette.background.default,
                    },
                    label: {
                        color: theme.palette.text.primary,
                    },
                    marginBottom: 1,
                }}
            />}

            <TableContainer component={Paper} >
                {device && (
                    <DeviceMgmtDialog
                        action={"edit"}
                        openState={showDialog}
                        setOpenState={setShowDialog}
                        device={device}
                        alarmProp={alarmProp}
                        deviceAction={editDevice}
                    />
                )}

                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{
                                border: 0,
                                "& th": {
                                    fontSize: '1.25rem', // Increase font size for the header row
                                },
                            }}
                        >
                            <TableCell sx={{ padding: "16px 6px" }}>{t("device_id")}</TableCell>
                            <TableCell sx={{ padding: "16px 6px" }}>{t("nickname")}</TableCell>
                            <TableCell sx={{ padding: "16px 6px"}}></TableCell>
                            <TableCell sx={{ padding: "16px 6px" }}>{t("type")}</TableCell>
                            <TableCell sx={{ padding: "16px 6px" }}>{t("model")}</TableCell>
                            <TableCell sx={{ padding: "16px 6px" }}>{t("location")}</TableCell>
                            <TableCell sx={{ padding: "16px 6px" }}>{t("organization")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((device: Device, i: number) => (
                            <TableRow
                                onClick={() => {
                                    setDevice(device);
                                    setShowDialog(true);
                                }}
                                key={i}
                                hover
                                sx={{
                                    "&:nth-of-type(odd)": {
                                        background: theme.palette.action.hover,
                                    },
                                    "&:nth-of-type(even)": {
                                        background: theme.palette.background.default,
                                    },
                                    cursor: "pointer",
                                }}
                            >
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{device.device_id}</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{device.nickname}</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px", display: 'flex', justifyContent: 'flex-end', alignItems:"center" }}>
                                <Typography variant={fontVariant}>&nbsp;</Typography>
                                {device.camera_id ? <VideoCameraFrontIcon /> : ""}
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{device.device_type}</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{device.device_model}</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{device.location}</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    <Typography variant={fontVariant}>{getOrganizationName(device.owner_organization)}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};
