import {
    Grid,
    Typography,
    useTheme,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    Checkbox,
    FormControlLabel
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { OrganizationHierarchyNode  } from './types'; // Define your types in a separate file or within components
import OrgService from "../../services/OrganizationService"
import IngestionServerDropdown from './IngestionServerDropdown';
import { IngestionServer } from "../../interfaces/IngestionServer";
import IngestionServerService from "../../services/IngestionServerService";
import useFetch from "../../hooks/useFetch";
import { Organization }  from "../../interfaces/Organization"
import CustomSelect from "../../components/CustomSelect";

interface CustomerEditDialogProps {
    nodeData?: Organization ;
    hierarchyNode?: OrganizationHierarchyNode ;
    openState: boolean;
    setOpenState: (show:boolean) => void;
    dialogMode: string;
    parentNode?: OrganizationHierarchyNode | null;
    childNodeType? : string;
    onNodeUpdate: (node: OrganizationHierarchyNode) => void;
    onNodeDeleted: (node_id: string, parent_id: string) => void;
    triggerRefresh: () => void;
    variants: string[][]
}

const HOTSTORE_DEFAULT_VAL    = 48;
const DEFAULT_DATA_RESOLUTION = 5 * 60; // 5 minutes
const DEFAULT_SENTIO_TYPE     = "standard";
const DEFAULT_AI_EXECUTION    = "N";

interface FetchDataResult {
    servers: IngestionServer[];
    serverLoading: boolean;
    serverError: any;
}

function useFetchIngestionSystems(refresh: number): FetchDataResult {
    const [cookies] = useCookies(['access_token']);
    const [, setTrigger] = useState (0);
    // Properly structured hook call
    const { data, loading:serverLoading, error:serverError } = useFetch({
        url: IngestionServerService.getAllIngestionServers(),
        access_token: cookies.access_token,
    });

    const [servers, setServers] = useState<IngestionServer[]>([]);

    useEffect(() => {
        if (!serverLoading && data) {
            setServers(data); // Assuming 'data' is in the correct format
        }
    }, [data, serverLoading]);

    useEffect(() => {
        // Increment trigger to refetch data when refresh is toggled
        if (refresh) {
            setTrigger(prev => prev + 1);
        }
    }, [refresh]);

    return { servers, serverLoading, serverError };
}




export const CustomerEditDialog = ({nodeData, onNodeUpdate, onNodeDeleted, triggerRefresh, hierarchyNode, parentNode, openState, setOpenState, dialogMode, variants } : CustomerEditDialogProps) => {

    console.log ("CustomerEditDialog:", "dialogMode:", dialogMode)
    console.log ("nodeData:     ", nodeData)
    //console.log ("parentNode:   ", parentNode)
    //console.log ("hierarchyNode:", hierarchyNode)

    const editedNode = useRef<OrganizationHierarchyNode> ();
    const theme = useTheme();
    const { t } = useTranslation();
    const [cookies, ,] = useCookies(["access_token"]);
    const [showSaveButton, setShowSaveButton] = useState (false);
    const [confirmDelete, setConfirmDelete] = useState (false);
    const [orgName, setOrgName] = useState <string> (nodeData ? nodeData?.organization_name ?? "" : "")
    const [sentioType, setSentioType]             = useState <string>(nodeData?.sentio_type || DEFAULT_SENTIO_TYPE)
    const [adminEmail, setAdminEmail]             = useState <string>(nodeData?.admin_email || "")

    const [availableLicenses, setAvailableLicenses] = useState <number> (nodeData?.num_licenses || 0)
    //const [loginDomain, setLoginDomain]           = useState <string>(nodeData?.login_domain || "")
    const [loginDomain, setLoginDomain] = useState<string>(nodeData?.login_domain ?? "");
    const [orgId, setOrgId] = useState <string> ("")

    const [hotstoreDuration, setHotstoreDuration] = useState <number>(nodeData?.hotstore_duration || HOTSTORE_DEFAULT_VAL)
    const [dataResolution, setDataResolution]     = useState <number> (nodeData?.data_resolution || DEFAULT_DATA_RESOLUTION)
    const [dataResolutionChanged, setDataResolutionChanged] = useState (false);

    const [aiExecution, setAiExecution]               = useState <string> (nodeData?.ai_execution || DEFAULT_AI_EXECUTION)
    const [, setAiExecutionChanged] = useState (false);

    const [sentioTypeChanged, setSentioTypeChanged]             = useState (false);
    const [orgNameChanged, setOrgNameChanged]                   = useState (false);
    const [hotstoreDurationChanged, setHotstoreDurationChanged] = useState (false);
    const [adminEmailChanged, setAdminEmailChanged]             = useState (false);
    const [loginDomainChanged, setLoginDomainChanged]           = useState (false);
    const [proxyServer, setProxyServer]               = useState <string> (nodeData?.proxy_device_server || "")
    const [proxyServerChanged, setProxyServerChanged] = useState (false);
    const [numLicenses, setNumLicenses] = useState <number> (0)
    const [numLicensesChanged, setNumLicensesChanged] = useState (false)

    const [ingestionSystem, setIngestionSystem] = useState <string> (nodeData?.ingestion_system || "")
    const [ingestionSystemChanged, setIngestionSystemChanged] = useState (false);
    const [ingestionTopic, setIngestionTopic] = useState <string | undefined> ()
    const [ingestionTopicChanged, setIngestionTopicChanged] = useState (false);
    const [consumerGroup, setConsumerGroup] = useState <string | undefined> ();
    const [consumerGroupChanged, setConsumerGroupChanged] = useState (false);
    const [ingestionServers, setIngestionServers] = useState<IngestionServer[]>([]);
    const [isAzureEventHub, setIsAzureEventHub] = useState (false);
    const [refreshData, ] = useState(0);
    const { servers } = useFetchIngestionSystems(refreshData);

    const [variant, setVariant] = useState <string> (nodeData?.variant ||  "")
    const [variantChanged, setVariantChanged] = useState (false)

    // Add state variables for checkboxes
    const [isProxyServerEnabled, setIsProxyServerEnabled] = useState(false);
    const [isLoginDomainEnabled, setIsLoginDomainEnabled] = useState(false);

    // Add checkbox handlers
    const handleProxyServerCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsProxyServerEnabled(event.target.checked);
    };

    const handleLoginDomainCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoginDomainEnabled(event.target.checked);
    };

    useEffect (() => {
        console.log ("Ingestion servers returned:", servers)
        setIngestionServers (servers)
    }, [servers])


    useEffect(() => {
        if (!nodeData) {
            return;
        }
        //console.log ("Update with nodeData:", nodeData)
        editedNode.current = JSON.parse (JSON.stringify (nodeData));
        //console.log ("- - - - - New editedNode:", editedNode.current)
        setOrgName (dialogMode === "edit" ? nodeData.organization_name ?? "" : "");
        setAdminEmail (dialogMode === "edit" ? nodeData.admin_email ?? "" : "");
        setLoginDomain(dialogMode === "edit" ? nodeData.login_domain ?? "" : "");
        setOrgId (dialogMode === "edit" ? nodeData.organization_id ?? "" : "");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setAiExecution (dialogMode === "edit" ? nodeData.ai_execution ?? DEFAULT_AI_EXECUTION : DEFAULT_AI_EXECUTION);
        setHotstoreDuration (dialogMode === "edit" ? nodeData.hotstore_duration ?? HOTSTORE_DEFAULT_VAL : HOTSTORE_DEFAULT_VAL);
        setDataResolution (dialogMode === "edit" ? nodeData.data_resolution ?? DEFAULT_DATA_RESOLUTION : DEFAULT_DATA_RESOLUTION);
        setProxyServer (dialogMode === "edit" ? nodeData.proxy_device_server ?? "" : "");
        setIngestionSystem (dialogMode === "edit" ? nodeData.ingestion_system ?? "" : "");
        setIngestionTopic (dialogMode === "edit" ? nodeData.ingestion_topic ?? "" : "")
        setConsumerGroup (dialogMode === "edit" ? nodeData.ingestion_group ?? "$Default" : "$Default")
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setSentioType (dialogMode === "edit" ? nodeData.sentio_type ?? "" : "");
        setNumLicenses (dialogMode === "edit" ? nodeData.num_licenses ?? 0 : 0)
        setVariant (dialogMode === "edit" ? nodeData.variant ??  nodeData.variant : "");
        setOrgNameChanged (false)
        setAdminEmailChanged (false)
        setLoginDomainChanged (false)
        setSentioTypeChanged (false)
        setHotstoreDurationChanged (false)
        setDataResolutionChanged (false)
        setAiExecutionChanged (false)
        setProxyServerChanged (false)
        setIngestionSystemChanged (false)
        setIngestionTopicChanged (false);
        setConsumerGroupChanged (false);
        setNumLicensesChanged (false)
        setVariantChanged (false);
        setIsAzureEventHub (checkAzureEventHub (nodeData.ingestion_system))

        // eslint-disable-next-line
    }, [nodeData, dialogMode]);  // Depend on nodeData and dialogMode to update orgId
    
    // Calculate how many licenses are available for this child node.
    useEffect (() => {
        if (!parentNode)  {
            return;
        }
        const parent_pool     = parentNode.licensePool;
        const parent_assigned = parentNode.numAssignedLicenses;
        const nodePool        = dialogMode === "edit" ? nodeData?.num_licenses ?? 0 : 0;//?.licensePool || 0   ;//editedNode.current?.licensePool || 0; //nodeData?.numLicenses || 0
        const available       = parent_pool - parent_assigned + nodePool;
        setAvailableLicenses (available)
        if (dialogMode === "add") {
            setNumLicenses (0)
        }

        //console.log ("---------- Computing Available Licenses --------------")
        //console.log ("Parent node:", parentNode)
        //console.log ("item node:",nodeData)
        //console.log ("parent pool:", parent_pool, "parent assigned:", parent_assigned, "nodePool:", nodePool)
        //console.log ("Available licenses are ", available)
        
    }, [parentNode, nodeData, dialogMode])


    const handleClose = () => {
        setOpenState (false);
    }

    
    const handleSaveChanges = () => {
        console.log ("Handle save changes:", nodeData, dialogMode, parentNode)


        if (dialogMode === "add") {
            const data = {
                "organization_name"   : orgName,
                "admin_email"         : adminEmail,
                "data_resolution"     : dataResolution,
                "hotstore_duration"   : hotstoreDuration,
                "ai_execution"        : aiExecution,
                "sentio_type"         : sentioType,
                "proxy_device_server" : proxyServer,
                "login_domain"        : loginDomain,
                "ingestion_system"    : ingestionSystem,
                "ingestion_topic"     : ingestionTopic,
                "ingestion_group"     : consumerGroup,
                "parent_node"         : parentNode?.node_name,
                "num_licenses"        : numLicenses,
                "variant"             : variant !== "" ? variant : variants[0][0]
            }
            //console.log ("edit parameters:", data)
            OrgService.addOrganization (data, cookies.access_token)
                .then((res: any) => {
                    console.log("result:", res);
                    setShowSaveButton (false);
                    triggerRefresh ();
                    handleClose();
                })
                .catch((err: any) => {
                    if (err.status === 409) {
                        alert ("Could not create the customer instance - customer id already exists")
                    }
                    console.log(err);
                }).finally (() => {
                });

            return;
        } // if "add"


        // We have an EDIT
        if (!nodeData) {
            handleClose();
            return;
        }
        const data = {
            "organization_id"     : orgId,
            "organization_name"   : orgName,
            "admin_email"         : adminEmail,
            "data_resolution"     : dataResolution,
            "hotstore_duration"   : hotstoreDuration,
            "ai_execution"        : aiExecution,
            "sentio_type"         : sentioType,
            "proxy_device_server" : proxyServer,
            "login_domain"        : loginDomain,
            "ingestion_system"    : ingestionSystem,
            "ingestion_topic"     : isAzureEventHub ? ingestionTopic : undefined,
            "ingestion_group"     : isAzureEventHub ? consumerGroup : undefined,
            "num_licenses"        : numLicenses,
            "variant"             : variant
        }
        console.log ("Save Data:", data)
        OrgService.editOrganization (data, cookies.access_token)
        .then((res: any) => {

            if (hierarchyNode && (orgNameChanged || numLicensesChanged)) {
                const data = {
                    "node_name"    : hierarchyNode.node_name,
                    "description"  : orgName,
                    "num_licenses" : numLicenses,
                }
                console.log ("EDIT hierarhcyNode:", data)
        
                OrgService.editHierarchy (data, cookies.access_token)
                .then((res: any) => {
                    console.log("result:", res);
                    //targetOrg.organization_name = orgName;
                    //            window.location.href = "/sysmgmt";
                })
                .catch((err: any) => {
                    console.log(err);
                }).finally (() => {
                    triggerRefresh ();
                    handleClose();
                });

            }

            //console.log("result:", res);
            //nodeData.organization_name = orgName;
            setShowSaveButton (false);
            setOrgNameChanged (false)
            setAdminEmailChanged (false)
            setLoginDomainChanged (false)
            setSentioTypeChanged (false)
            setHotstoreDurationChanged (false)
            setDataResolutionChanged (false)
            setAiExecutionChanged (false)
            setProxyServerChanged (false)
            setIngestionSystemChanged (false)
            setIngestionTopicChanged (false);
            setConsumerGroupChanged (false);
            setNumLicensesChanged (false)
            setVariantChanged (false)
            //setNodeData (nodeData)
        })
        .catch((err: any) => {
            console.log(err);
        }).finally (() => {
            if (hierarchyNode) {
                onNodeUpdate (hierarchyNode)
            }

            if (editedNode.current) {
                onNodeUpdate(editedNode.current); // This now triggers the update in the parent component
            }

            triggerRefresh ();
            handleClose();
        });
    } // handleSaveChanges

    useEffect (() => {
        var showSave = false;
        switch (dialogMode) {
            case "edit" : {
                showSave = sentioTypeChanged || adminEmailChanged || orgNameChanged || loginDomainChanged || 
                hotstoreDurationChanged || dataResolutionChanged || ingestionTopicChanged || consumerGroupChanged ||
                 proxyServerChanged || ingestionSystemChanged || numLicensesChanged || variantChanged
                break;
            }

            case "add" : {
                showSave = orgName !== '' &&  ingestionSystem !== '';
                                
                break;
            }
        }
        setShowSaveButton (showSave)

    }, [dialogMode, sentioTypeChanged, adminEmailChanged, orgNameChanged, loginDomainChanged, 
        hotstoreDurationChanged,  dataResolutionChanged, variantChanged,
         proxyServerChanged,  ingestionSystemChanged, numLicensesChanged,
        ingestionSystem, ingestionTopic, ingestionTopicChanged, consumerGroup, consumerGroupChanged,
        adminEmail, orgName])


    const deleteVICU = () => {
        setConfirmDelete (true)
    };

    const cancelDelete = () => {
        setConfirmDelete (false)
    };

    const yesDelete = () => {
        console.log ("yesDelete: nodeData=", nodeData, "parentNode=",parentNode)
        if (!parentNode || !hierarchyNode) {
            return;
        }

        const organization_id = hierarchyNode.id;
        const parent_id       = parentNode.node_name;
        //OrgService.deleteCustomer ( cookies.access_token, organization_id, parent_id)
        OrgService.deleteHierarchyNode ( cookies.access_token, organization_id, parent_id)
        .then((res: any) => {
            setConfirmDelete (false)
            onNodeDeleted (organization_id, parentNode.id)
            triggerRefresh ();
            handleClose();
        })
        .catch((err: any) => {
            console.log(err);
        });

    };

    function checkAzureEventHub(server_id: string): boolean {
        return ingestionServers.some(server => server.server_id === server_id && server.serverType === "Azure EventHub");
      }
    
    const handleVariantChange = (value: string) => {
        setVariant(value);
    };
        

    const handleItemChange = (id: string, data: any) => {
        // eslint-disable-next-line
        const urlPattern = /^((https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?([\/\w.-]*)*\/?)?$/;
        // eslint-disable-next-line
        const addressPattern = /^(https?:)?(\/\/)?([\da-z.-]+)?(\.[a-z.]{2,6})?(:\d{0,5})?([\/\w .-]*)*\/?$/;
        // eslint-disable-next-line
        const stringPattern = /^[a-zA-Z0-9_-]*$/;

        switch (id) {

            case "orgName" : {
                const val: string = data;
                if (editedNode.current) { 
                    editedNode.current.organization_name = val;
                    editedNode.current.description = val;
                }
                setOrgName (val);
                setOrgNameChanged (val.trim() !== nodeData?.organization_name)
                break;
            }

            case "adminEmail" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.admin_email = val;
                setAdminEmail (val);
                setAdminEmailChanged (val.trim() !== nodeData?.admin_email)
                break;
            }

            case "loginDomain" : {
                const value: string = data;
                if (urlPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.login_domain = value;
                    setLoginDomain (value);
                }
                setLoginDomainChanged (value !== nodeData?.login_domain)
                break;
            }

            case "sentioType" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.sentio_type = val;
                setSentioType(val);
                setSentioTypeChanged (val !== nodeData?.sentio_type)
                console.log (val !== nodeData?.sentio_type)
                break;
            }

            case "hotStoreDuration" : {
                const value: number = Number (data as number);
                if (editedNode.current) editedNode.current.hotstore_duration = value;
                setHotstoreDuration(value);
                setHotstoreDurationChanged (value !== nodeData?.hotstore_duration)
                break;
            }

            case "dataResolution" : {
                const value: number = Number (data as number);
                if (editedNode.current) editedNode.current.data_resolution = value;
                setDataResolution(value);
                setDataResolutionChanged (value !== nodeData?.data_resolution)
                break;
            }

            case "aiExecution" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.ai_execution = val;
                setAiExecution(val);
                setAiExecutionChanged (val !== nodeData?.ai_execution)
                break;
            }

            case "proxyServer" : {
                const value: string = data;
                if (addressPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.proxy_device_server = value;
                    setProxyServer (value);
                }
                setProxyServerChanged (value !== nodeData?.proxy_device_server)
                break;
            }


            case "IngestionSystem" : {
                const old_system = nodeData?.ingestion_system ? nodeData.ingestion_system : ""
                const val: string = data;

                console.log ("New Ingestion System:", val)
                if (editedNode.current) {
                    editedNode.current.ingestion_system = val;
                }

                setIngestionSystem(val);
                setIngestionSystemChanged (val !== old_system)

                // Check if we are dealing with an Azure Event Hub
                setIsAzureEventHub (checkAzureEventHub (val))
                console.log ("AZURE:", checkAzureEventHub (val))
                //setIngestionKeyChanged (val !== nodeData?.ingestion_key)
                break;
            }

            case "ingestionTopic" : {
                const value: string = data;
                if (stringPattern.test(value) || value === '') {
                    if (editedNode.current) editedNode.current.ingestion_topic = value;
                    setIngestionTopic(value);
                  }
                setIngestionTopicChanged (value !== nodeData?.ingestion_topic)
                break;
            }

            case "consumerGroup" : {
                const val: string = data;
                if (editedNode.current) editedNode.current.ingestion_group = val;
                setConsumerGroup(val);
                setConsumerGroupChanged (val !== nodeData?.ingestion_group)
                break;
            }

            case "numLicenses" : {
                var value: number = Number (data as number);

                if (value > availableLicenses) {
                    value = availableLicenses;
                }
                if (editedNode.current) { editedNode.current.licensePool = value; }

                setNumLicenses (value)
                setNumLicensesChanged (value !== nodeData?.num_licenses)
                //setNumLicenses(value);
                break;
            }

        } // switch


    } // handleItemChange
   
    //console.log ("Org info:", orgInfo)
    //console.log ("nodeData before rendering:", orgId)
    console.log ("Sentio type:", sentioType)
    console.log ("SentioVariants:", variants)
    return (
        <>
            <Dialog open={confirmDelete} >
                <DialogTitle> {t ("confirm_organization_delete") } </DialogTitle>
                <DialogContent>
                    <Typography>{t("delete_organization_notification")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                        <Button variant="outlined" onClick={cancelDelete}> { t("cancel") }</Button>
                        <Button variant="contained" onClick={yesDelete} color="warning">  { t("yes_delete") }</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
    
            <Dialog open={openState} onClose={handleClose} maxWidth="md" >
                <DialogTitle>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Box>{t("customer_administration")}</Box>
                        </Grid>
                        {dialogMode === "edit" && <Grid item>
                            <TextField
                                label={t("organization_id")}
                                id="org_id"
                                type=""
                                value={orgId}
                                onChange={(e) => handleItemChange("orgId", e.target.value)}
                                disabled={true}
                                InputLabelProps={{ shrink: true }}   
                                placeholder="Enter unique ID"                 
                            
                            />
                        </Grid>}
                    </Grid>
                </DialogTitle>
    
                <DialogContent>
    
                    <Grid container item pt={3} mb={1} spacing={2}>
    
                        {/* First Row */}
                        <Grid item xs={12}>
                            <Grid container direction="row" columnSpacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        label={t("organization_name")}
                                        id="org_name"
                                        value={orgName}
                                        placeholder="Descriptive name to be shown in the GUI"
                                        onChange={(e) => handleItemChange("orgName", e.target.value)}
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                     background: !orgNameChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},                                    
                                            label: { color: theme.palette.text.primary },
                                        }}
                                    />
                                </Grid>
    
                                <Grid item xs={6}>
                                    <Box display="flex" alignItems="center">
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            label={t("login_domain")}
                                            id="login_domain"
                                            placeholder="Web address for user login"
                                            value={loginDomain}
                                            onChange={(e) => handleItemChange("loginDomain", e.target.value)}
                                            disabled={!isLoginDomainEnabled}
                                            sx={{
                                                input: {
                                                    color: theme.palette.text.primary,
                                                    background: !loginDomainChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light
                                                },
                                                label: { color: theme.palette.text.primary },
                                            }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isLoginDomainEnabled}
                                                    onChange={handleLoginDomainCheckboxChange}
                                                />
                                            }
                                            label={t("enable")}
                                            sx={{ marginLeft: theme.spacing(1) }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid> {/* Row Container */}
    
                        {/* Other rows... */}
    
                        {/* Second Row */}
                        <Grid item xs={12}>
                            <Grid container direction="row" columnSpacing={1}>
                                <Grid item xs={12}>
  
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        label={t("rest_proxy_server")}
                                        id="rest_proxy_server"
                                        value={proxyServer}
                                        placeholder="REST Proxy Server Address"
                                        onChange={(e) => handleItemChange("proxyServer", e.target.value)}
                                        disabled={!isProxyServerEnabled}
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                     background: !proxyServerChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                            width: { md: 400 },
                                        }}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isProxyServerEnabled}
                                                onChange={handleProxyServerCheckboxChange}
                                                sx = {{ marginLeft: "10px"  }}
                                            />
                                        }
                                        label={t("enable")}
                                    />
                                </Grid>
    
                                {/* Other fields... */}
    
                            </Grid>
                        </Grid> {/* Row Container */}
    
                        {/* Third Row */}
                        <Grid item xs={12}>
                            <Grid container direction="row" columnSpacing={1}>
                                <Grid item>
                                    <IngestionServerDropdown 
                                        ingestionServers={ingestionServers} 
                                        defaultSelectedServer={ingestionSystem}
                                        onSelect={(e) => handleItemChange("IngestionSystem", e)}
                                        serverChanged={dialogMode === "edit" ? ingestionSystemChanged : false}
                                    />
                                </Grid>
    
                                <Grid item>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            type="number"
                                            label={t("license_pool")}
                                            id="license_pool"
                                            value={numLicenses}
                                            onChange={(e) => handleItemChange("numLicenses", e.target.value)}
                                            sx={{
                                                input: { color: theme.palette.text.primary, 
                                                         background: !numLicensesChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                                label: { color: theme.palette.text.primary },
                                                width: { md: 120 }
                                            }}
                                            inputProps={{
                                                min: 0,
                                                max: availableLicenses
                                            }}
                                        />
                                        <Typography
                                            onClick={() => { handleItemChange("numLicenses", availableLicenses) }}
                                            sx={{
                                                cursor: 'pointer',
                                                color: theme.palette.primary.main,
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                },
                                            }}>
                                            Max: {availableLicenses}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid> {/* Row Container */}
    

                        {/* Fourth Row */}
                        { isAzureEventHub && <Grid item xs={12}>
                            <Grid container direction="row" columnSpacing={1}>
                                <Grid item xs={6}>
  
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        label={t("ingestion_topic")}
                                        id="ingestion_topic"
                                        value={ingestionTopic}
                                        placeholder="Ingestion Topic"
                                        onChange={(e) => handleItemChange("ingestionTopic", e.target.value)}
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                     background: !ingestionTopicChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                            width: { md: 400 },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
  
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        label={t("consumer_group")}
                                        id="consumer_group"
                                        value={consumerGroup}
                                        placeholder="Consumer Group"
                                        onChange={(e) => handleItemChange("consumerGroup", e.target.value)}
                                        sx={{
                                            input: { color: theme.palette.text.primary, 
                                                    background: !consumerGroupChanged || !nodeData ? theme.palette.background.default : theme.palette.warning.light},
                                            label: { color: theme.palette.text.primary },
                                            width: { md: 400 },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid> } {/* Row Container */}


                        <Grid item xs={12}>
                            { variants && variants.length > 1 && <Grid container direction="row" columnSpacing={1}>
                                <Grid item xs={12}>
                                <CustomSelect
                                    label={ t("sentioweb_variant")}
                                    options={variants}
                                    value={variant}
                                    onChange={handleVariantChange}
                                    valueExtractor={(option: string[]) => option[0]}
                                    descriptionExtractor={(option: string[]) => option[1]}
                                    />

                            
                                
                                </Grid>
    
                                {/* Other fields... */}
    
                            </Grid>
                            }
                        </Grid> {/* Row Container */}

                    </Grid> {/* Outer container */}
    
                </DialogContent>
    
                <DialogActions>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                        <Button variant="outlined" onClick={handleClose}> {t("cancel")}</Button>
                        {showSaveButton && (
                            <Button onClick={handleSaveChanges} variant="outlined"
                                sx={{ background: theme.palette.warning.light }}
                            >
                                {t("save_changes")}
                            </Button>
                        )}
                        {dialogMode === "edit" &&
                            <Button variant="outlined" onClick={deleteVICU} >  {t("delete_organization")}</Button>
                        }
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}
