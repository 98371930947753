
import { Grid } from "@mui/material";
import WebRTCPlayer from "./WebRTC/WebRTCPlayer";


interface VideoTabProps {
    stream: string
}

export const VideoTab = ({stream } : VideoTabProps) => {

//"S702627"
    return (
    <Grid>
    <WebRTCPlayer video_server = "https://video.sentioweb.com"
            camera_id = {stream} 
            
    />

    </Grid>
    )
}
