import * as CONSTANTS from "../utils/constants/constants";
import axios from "./axiosInstance"; // Replace with the path to your axiosInstance.js file


//const axios = require("axios").default;



axios.interceptors.request.use((config: any) => {
    if (config.method === "delete" && config.data) {
        config.headers["Content-Type"] = "application/json";
        config.data = JSON.stringify(config.data);
    }
    return config;
});



class OrgService {
	static getAllOrganizations() {
		return CONSTANTS.HOST + "api/v1/organizations/";
	}
	static getHierarchyNodes() {
		return CONSTANTS.HOST + "api/v1/sysadmin/hierarchy/";
	}
    static editHierarchy(data:any, access_token: string) {
        return axios
            .patch(
                CONSTANTS.HOST + "api/v1/sysadmin/hierarchy/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static addHierarchyNode(data:any, access_token: string) {
        return axios
            .post(
                CONSTANTS.HOST + "api/v1/sysadmin/hierarchy/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static deleteHierarchyNode(access_token: string, node_id: string, parent_id: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/sysadmin/hierarchy/", {
                headers: { authorization: "Bearer " + access_token },
                params: {
                    node_id   : node_id,
                    parent_id : parent_id
                }
    
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }


    static getOrganization(org_id: string) {
        return CONSTANTS.HOST + "api/v1/organization/" + org_id + "/";
    }


/*
    getOrganization(data: any, access_token: string) {
        return axios
            .post(CONSTANTS.HOST + "api/v1/organization/", data, {
                headers: { authorization: "Bearer " + access_token },
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
*/

static editOrganization(data:any, access_token: string) {
        return axios
            .patch(
                CONSTANTS.HOST + "api/v1/organization/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }

    static addOrganization(data:any, access_token: string) {
        return axios
            .post(
                CONSTANTS.HOST + "api/v1/organization/", data, 
                {
                    headers: { authorization: "Bearer " + access_token },
                }
            )
            .then((response: any) => response) 
            .catch((err: any) => {
                console.log(err);
                return Promise.reject(err.response);
            });
    }
        
    static deleteOrganization(data: any, access_token: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/organization/", {
                headers: { authorization: "Bearer " + access_token },
                data: data
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
        
    static deleteCustomer(access_token: string, organization_id: string, parent_id: string) {
        return axios
            .delete(CONSTANTS.HOST + "api/v1/organization/", {
                headers: { authorization: "Bearer " + access_token },
                params: {
                    organization_id : organization_id,
                    parent_id       : parent_id
                }
    
            })
            .then((response: any) => response)
            .catch((err: any) => {
                console.log(err);
                return err.data;
            });
    }
        

}

export default OrgService;
