import { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Device } from "../../interfaces/Device";
import "../VicuManagement/VicuManagement.css";
import { ReactNode } from "react";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import DeviceService from "../../services/DeviceService";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../ReRoute/ServerProblem";
import QRCode from "react-qr-code";
import { getQRCode } from "../../utils/functions/QRData";
import { UserContext } from "../../context/UserContext";
import DeviceRow from "./DeviceRow";
import Legend from "./Legend";
import { useTranslation } from 'react-i18next';
import { getStatusDot, formatTimestamp } from "./DeviceRow"

interface Props {
    devicelist: Device[];
    children?: ReactNode;
    organization_id: string;
}

export const HEARTBEAT_GREEN  = 10;    // 10 seconds
export const HEARTBEAT_YELLOW = 600;   // 10 minutes
export const HEARTBEAT_GREY   = 86400; // 24 hours
export const HEARTBEAT_GREEN_COLOR = '#00C000';
export const HEARTBEAT_YELLOW_COLOR = 'orange';
export const HEARTBEAT_GREY_COLOR   = 'grey';
export const HEARTBEAT_NODATA_COLOR   = 'grey';


const getStatusValue = (timestamp: string | undefined) => {
    if (!timestamp) return 4; // Hollow status

    const currentTime = new Date().getTime();
    const activityTime = new Date(timestamp).getTime();
    const timeDiffSeconds = (currentTime - activityTime) / 1000;

    if (timeDiffSeconds <= HEARTBEAT_GREEN) return 1; // Green status
    else if (timeDiffSeconds <= HEARTBEAT_YELLOW) return 2; // Orange status
    else if (timeDiffSeconds <= HEARTBEAT_GREY) return 3; // Grey status
    else return 4; // Hollow status
};

export const MissionCentral = ({ organization_id, devicelist, children }: Props) => {
    const theme = useTheme();
    const [qrData, setQrData] = useState("");
    const userCtx = useContext(UserContext);
    const { t } = useTranslation();

    const [cookies, ,] = useCookies(["access_token"]);
    const [activityStatus, setActivityStatus] = useState<{ [key: string]: string }>({});
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const [filterValue, setFilterValue] = useState<number>(Infinity);
    const filterOptions = [
        { label: t("live"), value: HEARTBEAT_GREEN },
        { label: t("last_10_minutes"), value: HEARTBEAT_YELLOW },
        { label: t("last_24_hours"), value: HEARTBEAT_GREY },
        { label: t("all"), value: Infinity },
    ];
    
    const {
        data: alarmProfiles,
        loading: almLoading,
        error: almError,
    } = useFetch({
        url: DeviceService.getTypes(),
        access_token: cookies.access_token,
    });

    useEffect(() => {
        if (devicelist.length === 0) {
            setActivityStatus({});
        } else {
            DeviceService.getActivityStatus(cookies.access_token, organization_id, devicelist).then((response: any) => {
                setActivityStatus(response.activity || {});
            });
        }
    }, [devicelist, cookies.access_token, organization_id]);

    useEffect(() => {
        setSelectedDevice(null);
    }, [devicelist]);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            setActivityStatus((prevStatus) => {
                const updatedStatus = { ...prevStatus };
                Object.keys(updatedStatus).forEach((deviceId) => {
                    const activityTime = new Date(updatedStatus[deviceId]).getTime();
                    const timeDiffSeconds = (currentTime - activityTime) / 1000;
                    if (timeDiffSeconds > HEARTBEAT_GREEN && timeDiffSeconds <= HEARTBEAT_YELLOW) {
                        // Update to trigger re-render and change status color to orange
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_YELLOW && timeDiffSeconds <= HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to grey
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    } else if (timeDiffSeconds > HEARTBEAT_GREY) {
                        // Update to trigger re-render and change status color to hollow
                        updatedStatus[deviceId] = new Date(updatedStatus[deviceId]).toISOString();
                    }
                });
                return updatedStatus;
            });
        }, 1000); // Check every second

        return () => clearInterval(interval);
    }, []);

    const handleRowClick = (device: Device) => {
        setSelectedDevice(device);
        setQrData(userCtx?.user ? JSON.stringify(getQRCode(userCtx?.user, device)) : "");
    };

    const handleFilterChange = (event: any) => {
        setFilterValue(event.target.value);
    };

    const filterDevices = (devices: Device[]) => {
        const currentTime = new Date().getTime();
        return devices.filter(device => {
            const timestamp = activityStatus[device.device_id];
            if (!timestamp) return filterValue === Infinity;
            const activityTime = new Date(timestamp).getTime();
            const timeDiffSeconds = (currentTime - activityTime) / 1000;
            return timeDiffSeconds <= filterValue;
        });
    };

    const sortDevices = (devices: Device[]) => {
        return devices.sort((a, b) => {
            const statusA = getStatusValue(activityStatus[a.device_id]);
            const statusB = getStatusValue(activityStatus[b.device_id]);
            if (statusA !== statusB) return statusA - statusB;
            return a.device_id.toLowerCase().localeCompare(b.device_id.toLowerCase());
        });
    };

    const updateActivityStatus = useCallback((device_id: string, timestamp: string) => {
        setActivityStatus((prevStatus) => ({
            ...prevStatus,
            [device_id]: timestamp
        }));
    }, []);

    if (!alarmProfiles || almLoading) {
        return <Loading />;
    }
    if (almError) {
        return <ServerProblem />;
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {children}
            <Grid container direction="row" spacing={2}>
                <Grid item xs={8}>
                    {devicelist?.length > 0 ? (
                        <>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>{t('device_id')}</TableCell>
                                            <TableCell>{t('model')}</TableCell>
                                            <TableCell>{t('nickname')}</TableCell>
                                            <TableCell>{t('status')}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography sx={{ fontSize: '1.25rem', color: theme.palette.mode === 'light' ? 'white' : undefined }}>
                                                        {t('last_activity')}
                                                    </Typography>
                                                    <FormControl variant="outlined" sx={{ minWidth: 120, ml: 2 }}>
                                                        <InputLabel sx={{ color: theme.palette.mode === 'light' ? 'white' : undefined }}>
                                                            {t('filter')}
                                                        </InputLabel>
                                                        <Select
                                                            value={filterValue}
                                                            onChange={handleFilterChange}
                                                            label={t('filter')}
                                                            sx={{
                                                                color: theme.palette.mode === 'light' ? 'white' : undefined,
                                                                '& .MuiSelect-icon': {
                                                                    color: theme.palette.mode === 'light' ? 'white' : undefined,
                                                                }
                                                            }}
                                                        >
                                                            {filterOptions.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>{t(option.label)}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sortDevices(filterDevices(devicelist)).map((device, index) => (
                                            <DeviceRow
                                                key={device.device_id}
                                                index={index}
                                                device={device}
                                                selectedDevice={selectedDevice}
                                                activityStatus={activityStatus}
                                                handleRowClick={handleRowClick}
                                                updateActivityStatus={updateActivityStatus}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Legend />
                        </>
                    ) : (
                        <Typography>{ t("no_devices_to_show") }</Typography>
                    )}
                </Grid>
                <Grid item xs={4}>
                    {selectedDevice && (
                        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 4 }}>
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ color: theme.palette.primary.main, fontSize: '1.25rem' }}>{t('alias')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ color: theme.palette.primary.main, fontSize: '1.25rem' }}>{selectedDevice.nickname}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('device_id')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedDevice.device_id}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('type')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedDevice.device_type}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('model')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedDevice.device_model}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('serialnumber')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedDevice.device_serialnumber}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('location')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{selectedDevice.location}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{t('last_activity')}:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="body1">{getStatusDot(activityStatus[selectedDevice.device_id])} {formatTimestamp(activityStatus[selectedDevice.device_id])}</Typography>
                                </Grid>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <Box sx={{ backgroundColor: 'white', padding: 1 }}>
                                            <QRCode value={qrData} size={128} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
