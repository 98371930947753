import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import VICUService from "../../services/VICUService";
import useFetch from "../../hooks/useFetch";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Collapse, IconButton } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

interface UserAccessDialogProps {
  username: string;
  open: boolean;
  onClose: () => void;
  onOk: (updatedGroups: GroupType[]) => void;
}

export type GroupType = {
  vicu_id: string;
  vicu_name: string;
  vicu_type: string;
  assigned?: boolean;
};

export type TreeGroupType = {
  group_id: string;
  children: TreeGroupType[];
  vicu_id: string;
  group_name: string;
  vicu_type: string;
  assigned?: boolean;
};

const UserAccessDialog: React.FC<UserAccessDialogProps> = ({ open, onClose, onOk, username }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [cookies, ,] = useCookies(["access_token"]);
  const { t } = useTranslation();
  const [treeGroups, setTreeGroups] = useState<TreeGroupType[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<{ [key: string]: boolean }>({});

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const toggleChildrenAssignment = (children: TreeGroupType[], assigned: boolean): TreeGroupType[] => {
    return children.map(child => ({
      ...child,
      assigned: assigned,
      children: toggleChildrenAssignment(child.children, assigned)
    }));
  };

  const handleCheckboxChange = (group_id: string) => {
    const toggleAssignment = (groups: TreeGroupType[]): TreeGroupType[] => {
      return groups.map(group => {
        if (group.group_id === group_id) {
          const newAssignedStatus = !group.assigned;
          return {
            ...group,
            assigned: newAssignedStatus,
            children: toggleChildrenAssignment(group.children, newAssignedStatus)
          };
        }
        return {
          ...group,
          children: toggleAssignment(group.children)
        };
      });
    };

    const updatedTreeGroups = toggleAssignment(treeGroups);
    setTreeGroups(updatedTreeGroups);
  };

  const handleToggleExpand = (group_id: string) => {
    setExpandedNodes(prev => ({ ...prev, [group_id]: !prev[group_id] }));
  };

  // Retrieve Monitor Group membership for a given user
  const {
    data: monitorGroupList,
  } = useFetch({
    url: VICUService.getUserGroups(username),
    access_token: cookies.access_token,
  });

  const setSelected = (treeGroups: TreeGroupType[], assignedGroups: GroupType[]): TreeGroupType[] => {
    const assignedGroupIds = new Set(assignedGroups.map(group => group.vicu_id));
  
    const traverse = (nodes: TreeGroupType[]): TreeGroupType[] => {
      return nodes.map(node => ({
        ...node,
        assigned: assignedGroupIds.has(node.group_id),
        children: traverse(node.children)
      }));
    };
  
    return traverse(treeGroups);
  };
  
  useEffect(() => {
    if (monitorGroupList === null) {
      return;
    }
    //console.log("monitor group list returns:", monitorGroupList);

    const tree = setSelected (monitorGroupList.treeGroups, monitorGroupList.assignedGroups);

    setTreeGroups(tree);

}, [monitorGroupList]);

// Function to extract assigned items and rename properties
const extractAssignedItems = (nodes: TreeGroupType[]): GroupType[] => {
    let assignedItems: GroupType[] = [];
    const traverse = (node: TreeGroupType) => {
      if (node.assigned === true) {
        const { group_id: vicu_id, group_name: vicu_name, vicu_type, assigned } = node;
        assignedItems.push({ vicu_id, vicu_name, vicu_type, assigned });
      }
      node.children.forEach(traverse);
    };
    nodes.forEach(traverse);
    return assignedItems;
};


  const handleOkClick = () => {
    const assignedItems = extractAssignedItems(treeGroups);
    //console.log ("assignedItems:", assignedItems)
    //console.log ("treegroups:", treeGroups)
    onOk(assignedItems); // Call the callback prop with treeGroups
    onClose();
  };

  const filterTree = (nodes: TreeGroupType[], searchTerm: string): TreeGroupType[] => {
    return nodes
      .map(node => {
        if (node.group_name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return { ...node, children: filterTree(node.children, searchTerm) };
        } else {
          const filteredChildren = filterTree(node.children, searchTerm);
          if (filteredChildren.length > 0) {
            return { ...node, children: filteredChildren };
          }
        }
        return null;
      })
      .filter(node => node !== null) as TreeGroupType[];
  };

  const filteredTreeGroups = filterTree(treeGroups, searchTerm);

  const renderTree = (nodes: TreeGroupType[], level: number = 0) => (
    <List style={{ padding: 0 }}>
      {nodes
        .filter(node => node.group_name !== "syspool")  // Exclude nodes with group_name "syspool"
        .map(node => (
          <div key={node.group_id} style={{ paddingLeft: level === 0 ? 0 : 40, marginBottom: 4 }}>
            <ListItem dense style={{ marginBottom: level === 0 ? 2 : 4, padding: 0 }}>
              {level === 0 && node.children.length > 0 && (
                <IconButton onClick={() => handleToggleExpand(node.group_id)}>
                  {expandedNodes[node.group_id] ? <ExpandMore /> : <ChevronRight />}
                </IconButton>
              )}
              <ListItemText primary={node.group_name} />
              <Checkbox
                style={{ marginLeft: "auto" }}
                checked={node.assigned ?? false}
                onChange={() => handleCheckboxChange(node.group_id)}
                edge="end"
              />
            </ListItem>
            {node.children.length > 0 && (
              <Collapse in={expandedNodes[node.group_id]} timeout="auto" unmountOnExit>
                {renderTree(node.children, level + 1)}
              </Collapse>
            )}
          </div>
        ))}
    </List>
  );
  

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("manage_group_access : ") + username}</DialogTitle>
      <DialogContent>
        <TextField placeholder={t("type_to_filter")} variant="outlined" fullWidth onChange={handleFilter} />
        {renderTree(filteredTreeGroups)}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">{t("cancel")}</Button>
        <Button onClick={handleOkClick} color="primary">{t("ok")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserAccessDialog;
