import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SyntheticEvent, Fragment, useEffect } from "react";
import { useTheme, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import GenericDropdown from "../../pages/DeviceManagement/GenericDropdown";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    <Fragment>{children}</Fragment>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

interface Props {
    tabNames: string[];
    tabComponents?: React.ReactNode[];
    value: number; 
    onTabChange: (newValue: number) => void;
    dropdownOptions?: { value: string; label: string }[]; // Add dropdownOptions prop
    selectedDropdownValue?: string;
    dropdownTitle?: string;
    onDropdownChange?: (value: string) => void;
}

export default function CustomTabs({ tabNames, tabComponents, value, onTabChange, dropdownOptions, dropdownTitle, selectedDropdownValue, onDropdownChange }: Props) {
    const handleChange = (_: SyntheticEvent, newValue: number) => {
        onTabChange(newValue); // <- Here
    };
    const location = useLocation();
    const theme = useTheme();

    useEffect(() => {
        const state = location.state as { startingTab?: number };
        if (state && state.startingTab !== undefined) {
            onTabChange(state.startingTab);
        }
    }, [location.state, onTabChange]);
    
    const handleDropdownChange = (value: string) => {
        if (onDropdownChange) {
            onDropdownChange(value);
        }
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={10}>
                <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        sx={{
                            '& .MuiTab-root': {
                                minWidth: 'auto',
                                paddingLeft: theme.spacing(1),
                                paddingRight: theme.spacing(1),
                            },
                        }}
                    >

                        {tabNames.map((tab, i) => {
                            const isSysPoolTab = tab.toLowerCase() === 'manage groups';
                            return (
                                <Tab
                                    key={tab}
                                    label={
                                        <span
                                            style={{
                                                color: isSysPoolTab ? 'orange' : theme.palette.text.primary,
                                                fontSize: isBrowser ? "inherit" : "12px",
                                            }}
                                        >
                                            {tab}
                                        </span>
                                    }
                                    {...a11yProps(i)}
                                />
                            );
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '10px' }}>
                    {dropdownTitle && <GenericDropdown
                        options={dropdownOptions || []}
                        selectedValue={selectedDropdownValue || ''}
                        onChange={handleDropdownChange}
                        title={dropdownTitle}
                        sx={{ minWidth: 200 }}
                    />}
                </Grid>
            </Grid>
            {tabComponents?.map((tab, i) => {
                return (
                    <TabPanel key={i} value={value} index={i}>
                        {tab}
                    </TabPanel>
                );
            })}
        </Box>
    );
}
