import { useTheme } from "@mui/material";

export const GetColorParamNEWS = (score: number | null | undefined) => {
    const theme = useTheme();
    if (score) {
        if (score === 1) return theme.palette.error.light;
        else if (score === 2) return theme.palette.error.dark;
        else if (score === 3) return theme.palette.error.main;
        return "initial";
    }
};
