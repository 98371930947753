import React from 'react';
import { ListItem, ListItemText } from '@mui/material';
import { useDrag } from 'react-dnd';
import { Device } from '../../interfaces/Device'

interface DeviceItemProps {
    device: Device;
    onRemove?: (device: Device) => void;
    onClick: () => void;
}

const DeviceItem: React.FC<DeviceItemProps> = ({ device, onRemove, onClick }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'DEVICE',
        item: device,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult && onRemove) {
                onRemove(device);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const displayName = device.nickname || device.device_id;

    return (
        <ListItem
            ref={drag}
            style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer', padding: '4px 0', margin: '0' }} // Adjust padding and margin
            onClick={onClick}
        >
            <ListItemText primary={`${displayName} (ID: ${device.device_id})`} />
        </ListItem>
    );
};

export default DeviceItem;
