import React from 'react';
import { Card, CardContent, Typography, Grid, TableContainer, Paper} from '@mui/material';
import { OrganizationHierarchyNode } from './types'; // Ensure this type definition is appropriate for your data structure
//import EditIcon from '@material-ui/icons/Edit';
import { Table, TableBody, TableCell, TableHead, TableRow,  } from '@mui/material';
import { SENTIO_TYPES, SENTIO_VARIANTS } from '../../interfaces/Organization';

interface NodeChildrenProps {
  node: OrganizationHierarchyNode | null;
  onSelectNode: (nodeId: string) => void;
  setSelectedNode: React.Dispatch<React.SetStateAction<OrganizationHierarchyNode | null>>;
  onDialogClose: () => void;  // Toggling the state to trigger useEffect
  onNodeChanged: (changedNode: OrganizationHierarchyNode) => void;
  onNodeDeleted: (node_id: string, parent_id: string) => void;
  nodeParent: OrganizationHierarchyNode | null;
}


const NodeChildren: React.FC<NodeChildrenProps> = ({ node, nodeParent, onNodeChanged, onSelectNode, }) => {
  //const theme = useTheme();

  if (!node) {
    return (
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" color="textSecondary">
            Select a node to view detailed information
          </Typography>
        </CardContent>
      </Card>
    );
  }

  
  const customerInfo = [
    { label: 'Customer Name:', data: node.customer?.organization_name },
    { label: 'Contact Email:', data: node.customer?.admin_email },
    { label: 'Platform Type:', data: SENTIO_TYPES[node.customer?.sentio_type || "standard"]},
    { label: 'SentioWeb Variant:', data: SENTIO_VARIANTS[node.customer?.variant || ""] },
    { label: 'AI Enabled:', data: node.customer?.ai_execution },
    { label: 'Hotstore Duration:', data: node.customer?.hotstore_duration },
    { label: 'Data Resolution:', data: node.customer?.data_resolution },
    { label: 'REST Server:', data: node.customer?.proxy_device_server },
    { label: 'License Pool:', data: node.customer?.num_licenses },
    { label: 'Ingestion System:', data: node.customer?.ingestion_system },
    { label: 'Kafka Topic:', data: node.customer?.ingestion_topic },
    { label: 'Consumer Group:', data: node.customer?.ingestion_group },
  ];

  const halfLength = Math.ceil(customerInfo.length / 2);
  const firstColumn = customerInfo.slice(0, halfLength);
  const secondColumn = customerInfo.slice(halfLength);


  const renderInfoPairs = (infoArray:any) => (
    <Grid container direction="column" spacing={1}>
      {infoArray.map((info:any, index:number) => (
        <Grid container key={index} spacing={2} alignItems="center">
          {/* Label Column */}
          <Grid item xs={5}>
            <Typography variant="body1">
              {info.label}
            </Typography>
          </Grid>
          {/* Data Column */}
          <Grid item xs={7}>
            <Typography variant="body1">{info.data ?? 'N/A'}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
  return (
    
    <Card variant="outlined">

      <CardContent >

        { node.node_type === "customer" && 
          <Grid container>
           

           <Grid container spacing={1} sx={{ paddingLeft: 2, paddingTop: 1 }}>
              {/* First Column */}
              <Grid item xs={6} >{renderInfoPairs(firstColumn)}</Grid>

              {/* Second Column */}
              <Grid item xs={6}>{renderInfoPairs(secondColumn)}</Grid>
            </Grid>
          </Grid>

        }
        
        { node.node_type !== "customer" && (node.nodes && node.nodes.length > 0 ? (
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>

            <Table stickyHeader aria-label="simple table" >
              <TableHead>
                <TableRow>
                  <TableCell align="left"> 
                      Description
                  </TableCell>

                  <TableCell align="right">
                    License Pool
                  </TableCell>

                  { node.node_type !== "domain" && <TableCell align="right">
                    Assigned Licenses
                  </TableCell>}

                  { node.node_type !== "domain" && <TableCell align="right">
                    Available Licenses
                  </TableCell>}

                </TableRow>
              </TableHead>
              <TableBody>
                {node.nodes.map((childNode: any) => (
                  <TableRow 
                  key={childNode.id}
                  onClick={() => {
                    onSelectNode(childNode.id)
                  }}

                  style={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">{childNode.description || 'No description provided'}</TableCell>
                    <TableCell align="right">{childNode.licensePool}</TableCell>
                    { node.node_type !== "domain" &&  <TableCell align="right">{childNode.numAssignedLicenses}</TableCell>}
                    { node.node_type !== "domain" &&  <TableCell align="right">{childNode.licensePool - (Number (childNode.numAssignedLicenses) || 0)}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" color="primary">No information available</Typography>
          ))
        }


      </CardContent>
    </Card>
  );
};


export default NodeChildren;
