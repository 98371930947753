/// services/auth.ts
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import UserService from './UserService'; // Ensure this is imported if needed

export const useAuth = () => {
  const history = useHistory();
  const { setUser } = useContext(UserContext)!;
  const [cookies, , removeCookie] = useCookies(['access_token', 'refresh_token']);

  const handleLogout = async () => {

    try {
      await UserService.blacklistToken(cookies.refresh_token); // Assuming blacklistToken is async
    } catch (err) {
      console.error(err);
    } finally {
      setUser(null); // Update context
      localStorage.removeItem("user"); // Clear local storage
      removeCookie("access_token"); // Clear access token cookie
      removeCookie("refresh_token"); // Clear refresh token cookie
      history.push("/login"); // Redirect to login page
    }
  };

  return {
    handleLogout
  };
};
