
import { Grid } from "@mui/material";
import { MiniMapView } from "./MiniMapView";
import { Device } from "../interfaces/Device";

interface MapTabProps {
    device: Device
}

export const MapTab = ({device } : MapTabProps) => {

//"S702627"
    return (
    <Grid>
        <MiniMapView device={device} />

    </Grid>
    )
}
