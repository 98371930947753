import * as CONSTANTS from "../utils/constants/constants";
import axios from "axios";

class ServerService {
    static async getServerParameters(access_token: string): Promise<any> {
        const url = CONSTANTS.HOST + "api/v1/sysadmin/serverparms/";
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    static async saveServerParameters(payload: any, access_token: string): Promise<any> {
        const url = CONSTANTS.HOST + "api/v1/sysadmin/serverparms/";
        try {
            const response = await axios.patch(url, payload, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }
}

export default ServerService;
