import React, { useState, useEffect } from 'react';
import { LiveDashboard } from '../../pages/Home/LiveDashboard';
import { VicuTabBar } from './VicuTabBar'; // Adjust the import path as necessary
import { Device } from "../../interfaces/Device";
import { VICU } from "../../interfaces/VICU";
import { useTranslation } from "react-i18next";
import { MapView } from "../../pages/Home/MapView/MapView";
import CustomTabs from "../tabs/CustomTabs";
import { SysPoolView } from "../../pages/Home/SysPoolView";
import { MissionCentral } from '../../pages/Home/MissionCentral';

interface EMSDashboardTabsProps {
    visibleDevices: Device[];
    monitorGroupList: VICU[];
    setSelectedVICU: (vicu: VICU | null) => void;
    selectedVICU: VICU | null;
    updateDeviceList: () => void;
    setDevices: (devices: Device[]) => void;
    useSyspool: boolean;
    dropdownOptions: { value: string; label: string }[]; // Add the dropdownOptions prop
    dropdownTitle: string;
    selectedDropdownItem: string | undefined;
    organization_id: string;
    onDropdownChange: (value: string) => void; // Add this prop
}

const EMSDashboardTabs: React.FC<EMSDashboardTabsProps> = ({
    organization_id,
    visibleDevices,
    monitorGroupList,
    setSelectedVICU,
    selectedVICU,
    updateDeviceList,
    setDevices,
    useSyspool,
    dropdownOptions,
    onDropdownChange,
    dropdownTitle,
    selectedDropdownItem
}) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>(
        selectedDropdownItem || (dropdownOptions.length > 0 ? dropdownOptions[0].value : '')
    );

    useEffect(() => {
        if (selectedDropdownItem) {
            setSelectedDropdownValue(selectedDropdownItem);
        }
    }, [selectedDropdownItem]);

    //console.log("EMSDashboardTabs visibleDevices", visibleDevices);

    const tabNames = [
        t("mission_central"),
        t("live_dashboard"),
        t("mapview")
    ];

    const tabComponents = [
        <MissionCentral organization_id={organization_id} devicelist={visibleDevices}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </MissionCentral>,

        <LiveDashboard devicelist={visibleDevices}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </LiveDashboard>,

        <MapView devices={visibleDevices} setDevices={setDevices}>
            <VicuTabBar
                vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
                activateVicu={setSelectedVICU}
                activeVicu={selectedVICU}
                refreshDevices={updateDeviceList}
            />
        </MapView>
    ];

    const handleDropdownChange = (value: string) => {
        setSelectedDropdownValue(value);
        onDropdownChange(value);
    };

    if (useSyspool) {
        tabNames.push(t("manage_groups"));
        tabComponents.push(
            <SysPoolView devicelist={visibleDevices} setDevices={setDevices}>
                <VicuTabBar
                    vicuList={monitorGroupList}
                    activateVicu={setSelectedVICU}
                    activeVicu={selectedVICU}
                    refreshDevices={updateDeviceList}
                />
            </SysPoolView>
        );
    }

    return (
        <div>
            <CustomTabs
                tabNames={tabNames}
                tabComponents={tabComponents}
                value={currentTab}
                onTabChange={setCurrentTab}
                dropdownOptions={dropdownOptions} // Pass dropdownOptions to CustomTabs
                selectedDropdownValue={selectedDropdownValue}
                onDropdownChange={handleDropdownChange}
                dropdownTitle={dropdownTitle}
            />
        </div>
    );
};

export default EMSDashboardTabs;
