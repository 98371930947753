import { createContext, useMemo, useState } from "react";
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from "@mui/material/styles";

export const ThemeContext = createContext({
    toggleColorMode: () => {},
});

export default function ThemeContextProvider({ children }: any) {
    let color = localStorage.getItem("mode");
    const [mode, setMode] = useState<"light" | "dark">(color as "light" | "dark" || "light");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) =>
                    prevMode === "light" ? "dark" : "light"
                );
                //localStorage.setItem("mode", prevMode === "light" ? "dark" : "light");
            },
        }),
        []
    );

    let theme = useMemo(
        () =>
            createTheme({
                components: {
                    MuiPaper: {
                        styleOverrides: {
                            root: {
                                border: mode === 'dark' ? '1px solid rgba(63, 168, 243, 0.5)' : undefined, // Semi-transparent light blue for dark mode, default for light mode
                                padding: 0,
                            },
                        },
                    },
                    MuiCard: {
                        styleOverrides: {
                            root: {
                                border: mode === 'dark' ? '2px solid rgba(255, 255, 255, 0.40)' : '2px solid rgba(0, 0, 0, 0.42)',
                                boxShadow: 'none',
                                borderRadius: '10px',
                            }
                        }
                    },
                    MuiCardContent: {
                        styleOverrides: {
                            root: {
                                backgroundColor: mode === 'dark' ? "#222222" : "#ffffff"
                            }
                        }
                    },
                    MuiTableHead: {
                        styleOverrides: {
                            root: {
                                backgroundColor: "#004BA1",
                                '& .MuiTableCell-root': {
                                    color: "rgba(255, 255, 255, 0.87)",
                                    fontSize: '1.25rem'
                                },
                            },
                        },
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            stickyHeader: {
                                backgroundColor: "#004BA1", // Ensure the color matches your theme
                                color: "rgba(255, 255, 255, 0.87)",
                            },
                        },
                    },
                    MuiDialog: {
                        styleOverrides: {
                            paper: {
                                color: mode === "dark" ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.95)",
                                opacity: 1,
                                boxShadow: 'none',
                                borderRadius: '10px',
                            }
                        }
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            notchedOutline: {
                                borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 1)' : undefined, // White for dark mode
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            switchBase: {
                                color: '#3FA8F3', // Default color for the switch thumb
                            },
                            colorPrimary: {
                                '&.Mui-checked': {
                                    color: '#3FA8F3', // Color when the switch is checked
                                },
                            },
                            track: {
                                opacity: 1,
                                backgroundColor: '#3FA8F3', // Color for the switch track
                                '$checked$checked + &': {
                                    opacity: 1,
                                    backgroundColor: '#3FA8F3', // Color for the switch track when checked
                                },
                            },
                        },
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                color: mode === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)', // White text for dark mode, black text for light mode
                            },
                        },
                    },
                    MuiSelect: {
                        styleOverrides: {
                            root: {
                                color: mode === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)', // White text for dark mode, black text for light mode
                            },
                            icon: {
                                color: mode === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)', // White icon for dark mode, black icon for light mode
                            },
                        },
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: mode === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)', // White label for dark mode, black label for light mode
                            },
                        },
                    },
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                color: mode === 'light' ? 'rgba(0, 0, 0, 0.87)' : 'rgba(255, 255, 255, 0.87)', // Ensure menu item text is visible in both light and dark modes
                            },
                        },
                    },
                },
                palette: {
                    mode,
                    ...(mode === "light" && {
                        primary: {
                            main: "#416BA9",
                            light: "#dddddd",
                            dark: "#222222"
                        },
                        text: {
                            primary: "rgba(0, 0, 0, .95)",
                            secondary: "rgba(0, 0, 0, 0.7)",
                            disabled: "rgba(0, 0, 0, 0.38)",
                        },
                        background: {
                            default: "#F9F9F9",
                        },
                        error: {
                            main: "#FF4433",
                            light: "rgb(255, 240, 167)",
                            dark: "rgb(245, 196, 134)",
                        },
                        info: {
                            main: "#28a745",
                            dark: "#F5F5F5",
                            light: "#ffffff",
                        },
                    }),
                    ...(mode === "dark" && {
                        primary: {
                            main: "#3FA8F3",
                            light: "#222222",
                            dark: "#dddddd",
                        },
                        text: {
                            primary: "rgba(255, 255, 255, 0.9)",
                            secondary: "rgba(195, 195, 195, .87)",
                            disabled: "rgba(255, 255, 255, 0.78)",
                        },
                        background: {
                            default: "#101010",
                        },
                        error: {
                            main: "#F29983",
                            light: "#FDF2B0",
                            dark: "#FCCD8C",
                        },
                        info: {
                            main: "#28a745",
                            dark: "#141414",
                            light: "#343434",
                        },
                    }),
                },
                breakpoints: {
                    values: { xs: 350, sm: 650, md: 900, lg: 1536, xl: 1836 },
                },
                typography: {
                    fontFamily: [
                        "-apple-system",
                        "BlinkMacSystemFont",
                        '"Segoe UI"',
                        "Roboto",
                        '"Helvetica Neue"',
                        "Arial",
                        "sans-serif",
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(","),
                    h1: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3', // Dark blue for light mode, light blue for dark mode
                    },
                    h2: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3',
                    },
                    h3: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3',
                    },
                    h4: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3',
                    },
                    h5: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3',
                    },
                    h6: {
                        color: mode === 'light' ? '#416BA9' : '#3FA8F3',
                    },
                    body1: {
                        color: mode === 'light' ? 'rgba(0, 0, 0, 0.95)' : 'rgba(255, 255, 255, 0.9)', // Black for light mode, white for dark mode
                    },
                    body2: {
                        color: mode === 'light' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)', // Black for light mode, white for dark mode
                    },
                },
            }),
        [mode]
    );
    
    theme = responsiveFontSizes(theme);
    return (
        <ThemeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
}
