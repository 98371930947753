import React from 'react';

import {
	Grid,
	Typography,
	useTheme,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
	Link,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import SensorsIcon from "@mui/icons-material/Sensors";
import SensorsOffIcon from "@mui/icons-material/Sensors";

//import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { ReactNode, useState } from "react";
import { Device } from "../../interfaces/Device";
import "../../pages/Home/Live/LiveUnit.css";
import { PatientInfo } from "../../pages/Home/Live/LiveUnit";
import { ConditionalLink } from "../../components/ConditionalLink";
import { useTranslation } from "react-i18next";
import { isBrowser } from "react-device-detect";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { EcgDurationSelector } from "./EcgDurationSelector";
import { useCookies } from "react-cookie";
import DeviceService from "../../services/DeviceService";
import { DialogCaseId } from "../DialogCaseId";
import { DeviceDialog } from "../../pages/Home/Device/DeviceDialog";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import { useDrag } from 'react-dnd';
import { User } from "../../interfaces/user/User";


interface Props {
	device?: Device;
	selectParams?: ReactNode;
	patientInfo?: PatientInfo;
	useLink?: boolean;
	connectStatus?: string;
	fullWidth?: boolean;
	isVideoOn?: boolean;
	startCamera?: () => void;
	alarmProp?: any;
	nParmsSelected?: number;
	showSettings?: boolean;
	showEkgRecording?: boolean;
	showPatientInfo?: boolean;
	showConnectionStatus?: boolean;
	showDeviceName?: boolean;
	showChartIcon?: boolean;
	showDeviceInfo?: boolean;
}

const LiveUnitHeader = ({
	device,
	selectParams,
	patientInfo,
	useLink = true,
	connectStatus,
	fullWidth = false,
	isVideoOn,
	startCamera,
	alarmProp,
	nParmsSelected,
	showSettings = true,
	showChartIcon = true,
	showEkgRecording = true,
	showPatientInfo = true,
	showConnectionStatus = true,
	showDeviceName = true,
	showDeviceInfo = true,
}: Props) => {
	//console.log ("Drawing: LiveUnitHeader",  selectParams);
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [ecgOpen, setEcgOpen] = useState(false);
	const [ecgDuration, setEcgDuration] = useState(10);
	const [cookies, ,] = useCookies(["access_token"]);
	const [, setOpenExportSnackbar] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
    const [user] = useState<User | null>(
		// @ts-ignore
		JSON.parse(localStorage.getItem("user")) || null
	);

	const [{ isDragging }, drag] = useDrag({
		type: 'ITEM',
		item: { id: device?.device_id },
		canDrag: user?.is_admin || false, // allow dragging only if user?.is_admin is true
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	

	const { t } = useTranslation();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setOpen(false);
		}
	};

	const handleEcgDurationChange = (val: number) => {
		setEcgDuration(val);
	};

	const handleEcgRecording = () => {
		setEcgOpen(true);
		console.log("Record ECG");
	};

	const handleEcgClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setEcgOpen(false);
		}
	};

	const handleCSVExport = () => {
		handleFileExport("csv");
	};

	const handlePDFExport = () => {
		handleFileExport("pdf");
	};

	const handleJSONExport = () => {
		handleFileExport("json");
	};

	const handlePNGxport = () => {
		handleFileExport("png");
	};

	const handleFileExport = (export_type: string) => {
		if (!device) {
			return;
		}
		console.log("ECG recording: ", export_type);

		var max_time = new Date();
		max_time = new Date(max_time.getTime() - 2000); // Adjust for two seconds ago.
		const min_time = new Date(max_time.getTime() - ecgDuration * 1000);

		const tzoffset = new Date(max_time).getTimezoneOffset();
		const data = {
			device_id: device.device_id,
			device_type: device.device_type,
			device_model: device.device_model,
			patientinfo: patientInfo, //dataPacket.patientInfo,
			start_time: min_time.toISOString(),
			end_time: max_time.toISOString(),
			tzoffset: tzoffset,
			case_id: device.case_id,
			file_type: export_type,
		};

		setOpenExportSnackbar(true);
		console.log("Export ECG data:", data);
		DeviceService.exportEcg(data, cookies.access_token).then((res: any) => {
			var fileOfBlob = new File([res.data], "chart.tmp");

			const fileURL = window.URL.createObjectURL(fileOfBlob);
			// Setting various property values
			let alink = document.createElement("a");
			alink.href = fileURL;

			const d = new Date();
			const mm = d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth();
			const dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
			const HH = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
			const MM =
				d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();

			const ftime =
				d.getFullYear() + "-" + mm + "-" + dd + "_" + HH + "." + MM;

			alink.download =
				device.device_id + "_ECG_" + ftime + "." + export_type;
			alink.click();
			setOpenExportSnackbar(false);
		});

		setEcgOpen(false);
	};

	const newfolderTip: string = t("journal_chart");
	const recordEcgTip: string = t("record_ecg");
	const monitorLink: string = "/wavemonitor/" + device?.device_id;
	const careSummaryLink: string = "/caresummary/" + device?.device_id;
	const isPharlap = localStorage.getItem("SYSTEM_TYPE") === "PHARLAP";
	//const gridSize: GridSize | undefined = isBrowser ? 3 : undefined;

	const lastNameString  = patientInfo?.last_name ? patientInfo.last_name : "-"
	const firstNameString = patientInfo?.first_name ? patientInfo?.first_name : "-"
	const genderString    = patientInfo?.gender ? patientInfo?.gender : "?"
	const ageString       = patientInfo?.age ? patientInfo?.age : "-"
	const categoryString  = patientInfo?.category ? patientInfo?.category : "-"
	const patientString = lastNameString + ", " + firstNameString + " " + categoryString + " " + genderString + "/" + ageString;

	if (!device) { return <></>; }

	return (
		<>
			<Grid
				id="liveUnitHeader"
				container
				direction="row"
				justifyContent="space-between"
				className="monitorHeader"
				px={1}
				pt={1}
			>
				{/* Device Identifier */}
				<Grid
					item
					xs={6}
					sm={fullWidth ? 4 : 5}
					md={isPharlap ? 4 : 3}
					container
					direction="row"
				>
					{showConnectionStatus && connectStatus && (
						<>
							{connectStatus === "connected" ? (
								<SensorsIcon
									style={{ fontSize: 25 }}
									className="connectionOK"
								/>
							) : (
								<SensorsOffIcon
									style={{ fontSize: 25 }}
									className="connectionFail"
								/>
							)}
						</>
					)}
					{ showDeviceName && <div ref={drag} style={{  opacity: isDragging ? 0.5 : 1 }}>

						<ConditionalLink show={useLink} to={monitorLink}>
							<Typography
								color={theme.palette.common.white}
								textAlign="center"
								ml={1}
							>
								{device.nickname
									? device.nickname
									: device.device_id}
							</Typography>
						</ConditionalLink>
					</div>}
				</Grid>

				{/* Patient Identifier */}
				{!isPharlap && showPatientInfo && (
					<Grid item xs={5} sm={fullWidth ? 2 : 3} md={5}>
						<Typography
							color={theme.palette.common.white}
							textAlign="left"
						>
							{patientString}
						</Typography>
					</Grid>
				)}

				<Grid item xs={2} sm={fullWidth ? 2 : 3} md={isPharlap ? 3 : 1}>
					{isPharlap && isBrowser && (
						<Grid container alignSelf="center">
							<Button
								size={isBrowser ? "medium" : "small"}
								variant="text"
								sx={{
									bottom: 4,
									color: device.case_id
										? "white"
										: "lightgreen",
								}}
								onClick={() => setOpenDialog(true)}
							>
								{device.case_id
									? device.case_id
									: t("add_pet_name")}
							</Button>
						</Grid>
					)}
					
				</Grid>

				

				<Grid
					container
					item
					direction="row"
					xs={4}
					md={3}
					justifyContent="right"
					>
					{/* Settings */}
					{isPharlap && startCamera && (
						<Grid
							container
							item
							xs={isPharlap ? 2 : 4}
							justifyContent="center"
							onClick={startCamera}
							sx={{
								cursor: "pointer",
								color: theme.palette.common.white,
							}}
						>
							{isVideoOn ? <VideocamOffIcon /> : <VideocamIcon />}
						</Grid>
					)}

					{/* CareSummary Info */}
					{ showChartIcon && <Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="right"
						>
						<Tooltip title={newfolderTip}>
							<Link target="_blank" href={careSummaryLink}>
								<QueryStatsIcon
									style={{
										cursor: "pointer",
										color: "lightgreen",
										fontSize: 22,
									}}
								/>
							</Link>
						</Tooltip>
					</Grid>}

					{/* ECG Recording */}
					{ showEkgRecording && <Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="right"
						>
						<Tooltip title={recordEcgTip}>
							<MonitorHeartIcon
								style={{
									cursor: "pointer",
									color: "lightgreen",
									fontSize: 22,
								}}
								onClick={handleEcgRecording}
							/>
						</Tooltip>
					</Grid>}

					{/* Waveform/Parameter settings */}
					{ showSettings && <Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="right"
						>
						{selectParams && isBrowser && (
							<SettingsIcon
								style={{
									cursor: "pointer",
									color: "white",
									fontSize: 22,
								}}
								onClick={handleClickOpen}
							/>
						)}
					</Grid>}

					{/* Monitor Settings */}
					{ showDeviceInfo && <Grid
						container
						item
						xs={isPharlap ? 2 : 3}
						justifyContent="right"
						>
						{selectParams && isBrowser && (
							<DisplaySettingsIcon
								style={{
									cursor: "pointer",
									color: "white",
									fontSize: 22,
								}}
								onClick={() => setShowDialog(true)}
							/>
						)}
					</Grid>}
				</Grid>
			</Grid>

			{/* Waveform Selector Dialog*/}
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle> {t("select_waveforms")} </DialogTitle>
				<DialogContent>{selectParams}</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}> {t("close")}</Button>
				</DialogActions>
			</Dialog>

			{/* ECG Recording Dialog*/}
			<Dialog open={ecgOpen} onClose={handleEcgClose} maxWidth="md">
				<DialogTitle> {t("record_ecg")} </DialogTitle>
				<DialogContent>
					<Grid container item pt={3} mb={1}>
						<Grid container direction="row" columnSpacing={1}>
							<Grid item>
								<EcgDurationSelector
									sliderWidth={300}
									defaultval={10}
									setParmInterval={setEcgDuration}
									handleChange={handleEcgDurationChange}
									title={t("set_ecg_recording_duration")}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid container direction="row">
						<Button
							variant="contained"
							onClick={handlePDFExport}
							style={{ margin: 5 }}
						>
							{t("export_to_pdf")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handleCSVExport}
							style={{ margin: 5 }}
						>
							{t("export_to_csv")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handleJSONExport}
							style={{ margin: 5 }}
						>
							{t("export_to_json")}
						</Button>
						<Button
							variant="contained"
							disabled
							onClick={handlePNGxport}
							style={{ margin: 5 }}
						>
							{t("export_to_png")}
						</Button>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleEcgClose}> {t("close")}</Button>
				</DialogActions>
			</Dialog>
			<DialogCaseId
				device={device}
				openDialog={openDialog}
				setOpenDialog={setOpenDialog}
			/>
			{ showDialog && alarmProp && <DeviceDialog
				device={device}
				openState={showDialog}
				setOpenState={setShowDialog}
				alarmProp={alarmProp}
			/>
			}
		</>
	);
};

  
export default React.memo(LiveUnitHeader, (p1: Props, p2: Props) => {
	// Implement custom comparison
	// Return true if passing nextProps to render would return
	// the same result as passing prevProps to render,
	// otherwise return false
	if (p1.connectStatus !== p2.connectStatus) return false;
	if (p1.device !== p2.device) return false;
	if (p1.patientInfo !== p2.patientInfo) return false;
	if (p1.isVideoOn !== p2.isVideoOn) return false;
	if (p1.alarmProp !== p2.alarmProp) return false;
	if (p1.nParmsSelected !== p2.nParmsSelected) return false;

	return true;
  });
  