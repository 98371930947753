import { User } from "../../interfaces/user/User"
import { Device } from "../../interfaces/Device";
import * as CONSTANTS from "../constants/constants";


export const getQRCode = (user: User, device: Device) => {
    if (!user?.qr_data) {
        return {}
    }
    const qr_data = JSON.parse(user.qr_data);
    qr_data["device_id"] = device.device_id;
    qr_data["device_type"] = device.device_type;
    qr_data["device_nickname"] = device.nickname;
    qr_data["sno"] = device.device_serialnumber;
    qr_data["org"] = user.organization;
    qr_data["api"] = CONSTANTS.HOST;

    return qr_data
}
