import React from 'react';
import { Grid, FormControlLabel, Typography, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface GenericSwitchProps {
    isActive: boolean;
    toggleSwitch: () => void;
    leftLabel: string;
    rightLabel: string;
    leftAction: () => void;
    rightAction: () => void;
}

const GenericSwitch: React.FC<GenericSwitchProps> = ({
    isActive,
    toggleSwitch,
    leftLabel,
    rightLabel,
    leftAction,
    rightAction,
}) => {
    const theme = useTheme();

    return (
        <Grid container alignItems="center">
            <Grid item>
                <FormControlLabel
                    control={<div />} // Empty control as placeholder
                    label={
                        <Typography
                            variant="h5"
                            sx={{
                                color: isActive ? theme.palette.text.disabled : theme.palette.text.primary,
                                cursor: 'pointer',
                            }}
                        >
                            {leftLabel}
                        </Typography>
                    }
                    labelPlacement="end"
                    onClick={leftAction}
                    sx={{ marginRight: 0 }} // Adjust spacing if necessary
                />
            </Grid>
            <Grid item>
                <Switch
                    checked={isActive}
                    onChange={toggleSwitch}
                    inputProps={{ 'aria-label': 'Toggle switch' }}
                    sx={{
                        '& .MuiSwitch-track': {
                            backgroundColor: theme.palette.primary.main,
                        },
                        '& .MuiSwitch-thumb': {
                            color: theme.palette.primary.main,
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<div />} // Empty control as placeholder
                    label={
                        <Typography
                            variant="h5"
                            sx={{
                                color: isActive ? theme.palette.text.primary : theme.palette.text.disabled,
                                cursor: 'pointer',
                            }}
                        >
                            {rightLabel}
                        </Typography>
                    }
                    labelPlacement="end"
                    onClick={rightAction}
                    sx={{ marginLeft: 0 }} // Adjust spacing if necessary
                />
            </Grid>
        </Grid>
    );
};

export default GenericSwitch;
