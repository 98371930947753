import React, { ReactNode, useState, useEffect } from "react";
import {
    Grid,
    Button,
    Typography,
    useTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NEWS } from "../../interfaces/NEWS";
import { Device } from "../../interfaces/Device";
import { GetColorNEWS } from "../../utils/functions/GetColorNEWS";
import { CustomRow } from "../CustomRow";
import { PatientInfo } from "../../pages/Home/Live/LiveUnit";
import SettingsIcon from "@mui/icons-material/Settings";
import { ConditionalLink } from "../../components/ConditionalLink";
import SensorsIcon from "@mui/icons-material/Sensors";
import SensorsOffIcon from "@mui/icons-material/Sensors";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Import the caret-down icon
import GenericDropdown from "../../pages/DeviceManagement/GenericDropdown";
import { DropdownOption } from "../chart/CareSummaryInfo";
import { Intervention } from "../../components/chart/interfaces";


const ENABLE_NEWS = false;

interface Props {
    device: Device;
    news?: NEWS | null;
    connectStatus: string;
    patientInfo: PatientInfo | null;
    selectParams?: ReactNode;
    useLink?: boolean;
    dropdownComponent?: ReactNode; // Add the dropdownComponent prop
    selectedIntervention?: string | null; // Add selectedIntervention as a prop
    setSelectedIntervention?: (value: string) => void;
    showInterventionSelector?: boolean;
    showDeviceName?: boolean;
    showConnectStatus?: boolean;
	interventions?: Intervention[]
}


const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Formats the date to a readable string with both date and time
};

export const TopPartMonitorNEWS = ({
    device,
    news,
    patientInfo,
    connectStatus,
    selectParams,
    useLink = true,
    dropdownComponent, // Destructure the dropdownComponent prop
    selectedIntervention,
    showInterventionSelector = false,
    showDeviceName = true,
    showConnectStatus = true,
    setSelectedIntervention,
    interventions
}: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false); // State for the dropdown dialog

    const handleClickOpenSettings = () => {
        setOpen(true);
    };

    const handleCloseSettings = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };

    const handleOpenDropdownDialog = () => {
        setDropdownOpen(true);
    };

    const handleCloseDropdownDialog = () => {
        setDropdownOpen(false);
    };

    const monitorLink: string = "/wavemonitor/" + device.device_id + "/";
    const handleDropdownChange = (value: string) => {
        if (setSelectedIntervention) {
            setSelectedIntervention(value);
        }
    };
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
    useEffect(() => {
        //console.log ("Interventions:", interventions);
        if (!interventions) {
            return;
        }

        const hasNullValue = interventions.some((intervention: Intervention) => intervention.intervention_id === null);

        const dropOptions: DropdownOption[] = interventions
            .filter((intervention: Intervention) => intervention.intervention_id !== null)
            .map((intervention: Intervention): DropdownOption => ({
                value: intervention.intervention_id as string,
                label: `${intervention.intervention_id} : ${formatTimestamp(intervention.min_time)}`,
            }))
            .sort((a: DropdownOption, b: DropdownOption) => a.label.localeCompare(b.label));

        if (hasNullValue) {
            dropOptions.unshift({ value: "None", label: t("None") });
        }
        if (setSelectedIntervention) {
            setSelectedIntervention(dropOptions[dropOptions.length - 1]?.value || '');
        }
        setDropdownOptions(dropOptions);
        //updateInterventions(interventions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interventions]);
    return (
        <Grid container direction="row" item xs={12}>
            <Dialog open={open} onClose={handleCloseSettings}>
                <DialogTitle>{t("select_parameters")}</DialogTitle>
                <DialogContent>{selectParams}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettings}>{t("close")}</Button>
                </DialogActions>
            </Dialog>
            
            {/* Dialog for the dropdownComponent */}
            <Dialog
                open={dropdownOpen}
                onClose={handleCloseDropdownDialog}
                maxWidth="sm" // Set a maximum width for the dialog (you can adjust as needed)
                fullWidth // Ensure the dialog takes the full width of its container
            >
                <DialogTitle>{t("select_intervention")}</DialogTitle>
                <DialogContent
                    style={{ minWidth: "400px" }} // Adjust the minWidth to make the dropdown wider
                >
                    <Box sx={{ padding: 2, backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}>
                        <Grid item xs={12} style={{ marginTop: '10px' }}> {/* Set xs to 12 to make full-width */}
                            <GenericDropdown
                                options={dropdownOptions}
                                selectedValue={selectedIntervention || ''}
                                onChange={handleDropdownChange}
                                title={t("interventions")}
                                sx={{ width: 'auto', minWidth: 'auto', maxWidth: '100%' }} // Allow the dropdown to expand based on content
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDropdownDialog}>{t("close")}</Button>
                </DialogActions>
            </Dialog>

            {/* container for header row */}
            <Grid
                container
                sx={{
                    bgcolor: "#004BA1",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    padding: "3px",
                }}
                direction="row"
                justifyContent="right"
                alignItems="center"
                >
                {showDeviceName && (
                    <Grid container item xs={7} direction="row">
                        {connectStatus && (
                            <>
                                {connectStatus === "connected" ? (
                                    <SensorsIcon
                                        style={{ fontSize: 25 }}
                                        className="connectionOK"
                                    />
                                ) : (
                                    <SensorsOffIcon
                                        style={{ fontSize: 25 }}
                                        className="connectionFail"
                                    />
                                )}
                            </>
                        )}
                        <ConditionalLink show={useLink} to={monitorLink}>
                            <Typography color={theme.palette.common.white} mx={1}>
                                {device.nickname ? device.nickname : device.device_id}
                            </Typography>
                        </ConditionalLink>
                    </Grid>
                )}
                
                {/* Insert hyperlink to open up a dialog with the dropdownComponent in it */}
                {showInterventionSelector && (
                    <Grid container item xs={6} justifyContent="center">
                        <Typography
                            color={theme.palette.common.white}
                            style={{ cursor: "pointer", display: "flex", alignItems: "center" }} // Align items and display flex
                            onClick={handleOpenDropdownDialog}
                        >
                            {selectedIntervention ? selectedIntervention : t("Select Intervention")}
                            <ArrowDropDownIcon 
                                style={{ marginLeft: "4px", verticalAlign: "middle" }} // Ensure the icon is centered vertically
                            />
                        </Typography>
                    </Grid>
                )}


                <Grid container item xs={1} justifyContent="right">
                    {selectParams && (
                        <SettingsIcon
                            style={{
                                color: "white",
                                fontSize: 20,
                                cursor: "pointer",
                            }}
                            onClick={handleClickOpenSettings}
                        />
                    )}
                </Grid>
            </Grid>{" "}
            {/* container for header row */}
            {/* container for NEWS row */}
            <Grid
                sx={{ bgcolor: "common.black" }}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <CustomRow alignItems="center">
                    <Typography color={theme.palette.common.white} px={1}>
                        {patientInfo?.first_name} {patientInfo?.last_name}
                    </Typography>

                    {ENABLE_NEWS && (
                        <Grid>
                            <CustomRow alignItems="center">
                                <Typography color={theme.palette.common.white}>
                                    NEWS:
                                </Typography>
                                <Typography
                                    sx={{
                                        marginLeft: "5px",
                                        padding: "3px",
                                        backgroundColor: GetColorNEWS(news?.score),
                                    }}
                                    color={
                                        GetColorNEWS(news?.score) !== "initial" &&
                                        news?.score
                                            ? theme.palette.common.black
                                            : theme.palette.common.white
                                    }
                                >
                                    {connectStatus === "connected" && news?.score
                                        ? news?.score + " - " + news?.scoretext
                                        : "--"}
                                </Typography>
                            </CustomRow>
                        </Grid>
                    )}
                </CustomRow>
            </Grid>{" "}
            {/* container for NEWS row */}
        </Grid>
    );
};
