import { Device } from "../interfaces/Device";
import { MapContainer, TileLayer } from "react-leaflet";
import DeviceMarker from "../pages/Home/MapView/DeviceMarker"
import { useEffect, useState } from "react";
import { Loading } from "../components/Loading";
import Box from "@mui/material/Box";
//import { useTheme } from "@mui/material";
import { isBrowser,  } from "react-device-detect";
import { ReactNode } from "react";
//import { useTranslation } from "react-i18next";

interface MiniMapViewProps {
    device: Device;
    children?: ReactNode;
}
export const MiniMapView = ({ device, children }: MiniMapViewProps) => {
    //const theme = useTheme();
    //const { t } = useTranslation();

    const [position, setPosition] = useState<any>(null);


    useEffect(() => {
        let isMounted = true; // Track whether the component is mounted

        const successCallback = (position: any) => {
            if (isMounted) {
                setPosition([position.coords.latitude, position.coords.longitude]);
            }
        };

        const errorCallback = (error: any) => {
            if (isMounted) {
                setPosition([63, 10]);
            }
        };

        if (navigator.geolocation && !position) {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
        }

        const timer = setTimeout(() => {
            if (isMounted) {
                setPosition([63, 10]);
            }
        }, 3000);

        return () => {
            isMounted = false; // Clean up: mark component as unmounted
            clearTimeout(timer); // Clear the timeout when the component unmounts
        };
    }, [position]);


    if (!position) return <Loading />;

    return (
        <>
            {children}
                <Box
                    sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: isBrowser ? "row" : "column",
                    }}
                >


                    <Box
                        component="main"
                        sx={{ flexGrow: 1, bgcolor: "background.default" }}
                    >
                        <MapContainer
                            style={{ height: "400px", width:"100%" }}
                            zoom={14}
                            center={position}
                            scrollWheelZoom={true}
                            closePopupOnClick={false}
                        >
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://tile.jawg.io/1a3bb38c-fc14-4a39-91b2-9bf3738d0850/{z}/{x}/{y}{r}.png?access-token=cl4DjZDEwMUCIKkxRgdeFZb4MaERLc8degcELVw9BcapTstY1jN1rlLIrHJHW2w5"
                            />


                                    <DeviceMarker
                                        key={device.device_id}
                                        device={device}
                                    />
                        </MapContainer>
                    </Box>
                </Box>
        </>
    );
};
