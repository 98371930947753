import { useContext, useMemo, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { useAlarmMessage } from "../../hooks/useAlarmMessage";
import { useCookies } from "react-cookie";
import useFetch from "../../hooks/useFetch";
import { LiveDashboard } from "../../pages/Home/LiveDashboard";
import { SysAdmin } from "../../pages/SysAdmin/SysAdmin";
import DeviceService from "../../services/DeviceService";
import VICUService from "../../services/VICUService";
import { Loading } from "../../components/Loading";
import { ServerProblem } from "../../pages/ReRoute/ServerProblem";
import { UserContext } from "../../context/UserContext";
import { Device } from "../../interfaces/Device";
import { Box } from "@mui/material";
import { VICU } from "../../interfaces/VICU";
import UtilsService from "../../services/UtilsService";
import useAdminSocket from "../../hooks/useAdminSocket";
import EMSDashboardTabs from "./EMSDashboardTabs";
import RPMDashboardTabs from "./RPMDashboardTabs";
import CMSDashboardTabs from "./CMSDashboardTabs";
import { useTranslation } from "react-i18next";

const sortDevicesByName = (devices: Device[]): Device[] => {
  return devices.sort((a, b) => {
    const nameA = a.nickname?.trim() !== "" ? a.nickname.toLowerCase() : a.device_id.toLowerCase();
    const nameB = b.nickname?.trim() !== "" ? b.nickname.toLowerCase() : b.device_id.toLowerCase();
    
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const CMSDashboard = () => {
  const context = useContext(UserContext);
  const [user, ] = useState(context?.user);
  const [cookies, ,] = useCookies(["access_token"]);
  const [selectedVICU, setSelectedVICU] = useState<VICU | null>(null);
  const [visibleDevices, setVisibleDevices] = useState<Device[]>([]);
  var token = cookies.access_token;
  const [adminDataPacket, setAdminDataPacket] = useState<any>(null);
  const [monitorGroupList, setMonitorGroupList] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<string>('');
  const [useSyspool, ] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const { data: devices, setData: setDevices, loading, error, refresh } = useFetch({
    url: DeviceService.getDevices(),
    access_token: token,
  });

  const updateDeviceList = () => {
    refresh();
  };

  const { data: initialMonitorGroupList, loading: monitorGroupLoading, error: monitorGroupError } = useFetch({
    url: VICUService.getAllGroups(),
    access_token: cookies.access_token,
  });


const getSelectedRegion = (): [string | undefined, string | undefined] => {
  const savedData = localStorage.getItem("selectedRegionVICU");
  if (savedData) {
      const regionDict: { [key: string]: string } = JSON.parse(savedData);
      const savedRegion = regionDict["region"];
      if (savedRegion) {
          const savedVicu = regionDict[savedRegion];
          return [savedRegion, savedVicu];
      }
  }
  return [undefined, undefined];
};


  useEffect(() => {
    if (initialMonitorGroupList === null) {
      return;
    }
    console.log ("initialMonitorGroupList:", initialMonitorGroupList)
    // Filter out any monitor group with id "syspool"
    const filteredMonitorGroupList = initialMonitorGroupList.filter((group: any) => group.group_name !== "syspool");

    console.log ("filteredMonitorGroupList:", filteredMonitorGroupList)

    //const savedData = localStorage.getItem("selectedRegionVICU");
    const [region, vicuId] = getSelectedRegion();
    console.log ("selectedRegion:", region, vicuId)
    if (region) {
      setSelectedRegion(region);
      const regionData = filteredMonitorGroupList.find((group: any) => group.group_id === region);
      if (regionData) {
        const vicuData = regionData.children.find((child: any) => child.group_id === vicuId);
        if (vicuData) {
          setSelectedVICU(vicuData);
          setMonitorGroupList(regionData.children);
        } else {
          setMonitorGroupList(regionData.children);
          setSelectedVICU(regionData.children[0]);
        }
      }
    } else {
      if (filteredMonitorGroupList.length > 0) {
        const activeMonitorList = filteredMonitorGroupList[0].children;
        if (activeMonitorList.length > 0) {
          setMonitorGroupList(activeMonitorList);
          setSelectedVICU(activeMonitorList[0]);
        }
      }
    }
  }, [initialMonitorGroupList]);

  useEffect(() => {
    if (selectedRegion && initialMonitorGroupList) {
      const regionData = initialMonitorGroupList.find((group: any) => group.group_id === selectedRegion);
      if (regionData) {
        //const savedData = JSON.parse(localStorage.getItem("selectedRegionVICU") || '{}');
        //const vicuId = savedData[selectedRegion];
        const [, vicuId] = getSelectedRegion();
        const vicuData = regionData.children.find((child: any) => child.group_id === vicuId);
        if (vicuData) {
          setSelectedVICU(vicuData);
          setMonitorGroupList(regionData.children);
        } else {
          setMonitorGroupList(regionData.children);
          setSelectedVICU(regionData.children[0]);
        }
      }
    }
  }, [selectedRegion, initialMonitorGroupList]);

  const dropdownOptions = useMemo(() => {
    if (!initialMonitorGroupList) return [];
    return initialMonitorGroupList
      .filter((group: any) => group.group_name !== "syspool") // Filter out groups with name "syspool"
      .sort((a: any, b: any) => a.group_name.localeCompare(b.group_name))
      .map((group: any) => ({
        value: group.group_id,
        label: group.group_name,
      }));
  }, [initialMonitorGroupList]);

  useEffect(() => {
    if (selectedRegion && selectedVICU) {
      const storedData = JSON.parse(localStorage.getItem("selectedRegionVICU") || '{}');
      storedData[selectedRegion] = selectedVICU.group_id;
      storedData["region"] = selectedRegion
      localStorage.setItem("selectedRegionVICU", JSON.stringify(storedData));
    }
  }, [selectedRegion, selectedVICU]);

  // Region Dropdown change callback
  const handleDropdownChange = (value: string) => {
    const filteredRegions = initialMonitorGroupList.filter((group: any) => group.group_id !== "syspool");
    const region = filteredRegions.find((group: any) => group.group_id === value);
    const regionChildren = region.children;
    console.log ("Region children:", regionChildren)
    setMonitorGroupList(regionChildren);

    if (regionChildren.length === 0) {
      setVisibleDevices([]);
      return;
    }

    const savedData = JSON.parse(localStorage.getItem("selectedRegionVICU") || '{}');
    if (savedData[value]) {
      const savedVICU = regionChildren.find((child: any) => child.group_id === savedData[value]);
      if (savedVICU) {
        setSelectedVICU(savedVICU);
      } else {
        setSelectedVICU(regionChildren[0]);
      }
    } else {
      setSelectedVICU(regionChildren[0]);
    }

    setSelectedRegion(value);
  };

  useEffect(() => {
    if (!selectedVICU) {
      return;
    }
    //console.log ("Selected VICU devices:", selectedVICU.devices)
    const sortedDevices = sortDevicesByName(selectedVICU.devices);
    setVisibleDevices(sortedDevices);
  }, [selectedVICU]);

  const { data: systemType, loading: systemTypeLoading, error: systemTypeError } = useFetch({
    url: UtilsService.getSystemType(),
    access_token: token,
  });

  useEffect(() => {
    if (systemType) {
      localStorage.setItem("SYSTEM_TYPE", systemType["system_type"]);
    }
  }, [systemType]);

  useEffect(() => {
    setSelectedVariant(user?.sentio_variant || '');
  }, [user?.sentio_variant, user?.is_admin]);

  useAdminSocket({ setAdminDataPacket });
  useAlarmMessage();

  useEffect(() => {
    if (adminDataPacket === null) {
      return;
    }
    let msgType = adminDataPacket["type"];
    let message = adminDataPacket["message"];
    let opcode = message["opcode"];
    switch (msgType) {
      case "admin-update":
        switch (opcode) {
          case "user-access":
            if (user?.username !== message["username"]) {
              return;
            }
            let groups = JSON.parse(message["assigned"]);
            setMonitorGroupList(groups);
            break;
          case "group-update":
            var updatedMonitorGroupList = monitorGroupList;
            for (var i = 0; i < updatedMonitorGroupList.length; i++) {
              let monGroup = updatedMonitorGroupList[i];
              if (monGroup.group_id === message.group_id) {
                monGroup.devices = message.devices;
                if (selectedVICU?.group_id === message.group_id) {
                  setVisibleDevices(monGroup.devices);
                }
              }
            }
            setMonitorGroupList(updatedMonitorGroupList);
            break;
        }
        break;
      default:
        console.log("Admin data packet:", adminDataPacket);
    }
  }, [adminDataPacket, selectedVICU?.group_id, monitorGroupList, user?.username]);

  if (user?.is_superuser) {
    return <SysAdmin />;
  }
  if (systemType && systemType["system_type"] === "PHARLAP") {
    return <LiveDashboard devicelist={devices} />;
  }

  if (!context || !context.user) {
    return <div>Loading or not available...</div>;
  }

  if (loading || systemTypeLoading || monitorGroupLoading) {
    return <Loading />;
  }

  if (error || systemTypeError || monitorGroupError) {
    return <ServerProblem />;
  }

  return (
    <Box sx={{ mx: 2, mt: 2 }}>
      {selectedVariant === "EMS" && (
        <EMSDashboardTabs
          visibleDevices={visibleDevices}
          organization_id={user?.organization || ''}
          monitorGroupList={monitorGroupList}
          setSelectedVICU={setSelectedVICU}
          selectedVICU={selectedVICU}
          updateDeviceList={updateDeviceList}
          setDevices={setDevices}
          dropdownOptions={dropdownOptions}
          dropdownTitle={t ("region")}
          selectedDropdownItem={selectedRegion}
          onDropdownChange={handleDropdownChange}
          useSyspool={useSyspool}
        />
      )}
      {selectedVariant === "RPM" && (
        <RPMDashboardTabs
          visibleDevices={visibleDevices}
          monitorGroupList={monitorGroupList}
          setSelectedVICU={setSelectedVICU}
          selectedVICU={selectedVICU}
          updateDeviceList={updateDeviceList}
          setDevices={setDevices}
          useSyspool={useSyspool}
        />
      )}
      {selectedVariant === "CMS" && (
        <CMSDashboardTabs
          visibleDevices={visibleDevices}
          monitorGroupList={monitorGroupList}
          setSelectedVICU={setSelectedVICU}
          selectedVICU={selectedVICU}
          updateDeviceList={updateDeviceList}
          setDevices={setDevices}
          useSyspool={useSyspool}
        />
      )}
    </Box>
  );
};
