import { Device } from "../../interfaces/Device";

export function sortDevices(devices: Device[]): Device[] {
    return devices.sort((a, b) => {
        const keyA = a.nickname?.toLowerCase() || a.device_id.toLowerCase();
        const keyB = b.nickname?.toLowerCase() || b.device_id.toLowerCase();
        
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
    });
}
