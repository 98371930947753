import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Card, CardContent, Typography, Grid, Button, Paper, Checkbox,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Box
} from '@mui/material';
import { OrganizationHierarchyNode, PoolDevice } from './types'; // Ensure this type definition is appropriate for your data structure
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import DeviceService from "../../services/DeviceService";
import { useCookies } from 'react-cookie';
import PoolDeviceDialog from "./PoolDeviceDialog";
import EditIcon from '@mui/icons-material/Edit';
import AssignDeviceDialog from './AssignDeviceDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import UploadIcon from '@mui/icons-material/Upload';
import { UserContext } from "../../context/UserContext";

interface DeviceChildrenProps {
  node: OrganizationHierarchyNode | null;
  onDialogClose: () => void;  // Toggling the state to trigger useEffect
  onNodeChanged: (changedNode: OrganizationHierarchyNode) => void;
  onNodeDeleted: (node_id: string, parent_id: string) => void;
  nodeParent: OrganizationHierarchyNode | null;
}

const DeviceChildren: React.FC<DeviceChildrenProps> = ({ node, nodeParent }) => {
  const [cookies, ,] = useCookies(["access_token"]);
  const [poolDevices, setPoolDevices] = useState<PoolDevice[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<PoolDevice[]>([]);
  const deviceDialogMode = useRef<string>("add");
  const [showDialog, setShowDialog] = useState(false);
  const [reloadDevices, setReloadDevices] = useState<number>(0);
  const [selectedDevices, setSelectedDevices] = useState<Set<string>>(new Set());
  const [filterText, setFilterText] = useState<string>('');
  const { t } = useTranslation();
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [registerAndAssign, setRegisterAndAssign] = useState (false);
  const [maxAvailableLicenses, setMaxAvailableLicenses] = useState (0);
  const [showRegister, setShowRegister] = useState (false)
	const userCtx = useContext(UserContext);

  //console.log ("DeviceChildren:", node)
  const handleOpenWarningDialog = () => {
    setShowWarningDialog(true);
  };
  
  const handleCloseWarningDialog = () => {
    setShowWarningDialog(false);
  };
  
  const handleConfirmDelete = () => {
    handleDeleteSelectedDevices();
    setShowWarningDialog(false);
  };
  
  useEffect(() => {
    if (!node) {
      return;
    }
    DeviceService.getPoolDevices(cookies.access_token, node.node_name).then((response: any) => {
      const sortedDevices = response.sort((a: PoolDevice, b: PoolDevice) => 
        a.device_id.localeCompare(b.device_id)
      );
      setPoolDevices(sortedDevices);
      setFilteredDevices(sortedDevices);
      setMaxAvailableLicenses (node.licensePool - sortedDevices.length)
    });
  }, [node, reloadDevices, cookies.access_token]);

  useEffect (() => {
    if (!userCtx?.user?.role?.roletype) {
      return
    }
    switch (userCtx?.user?.role?.roletype) {
      case "sysadmin" : 
      case "serveradmin" : {
        setShowRegister (true)
      }
    }
  },[userCtx])
  useEffect(() => {
    const lowercasedFilter = filterText.toLowerCase();
    const filteredData = poolDevices.filter(item =>
      item.device_id.toLowerCase().includes(lowercasedFilter) ||
      item.deviceType.toLowerCase().includes(lowercasedFilter) ||
      item.device_serialnumber.toLowerCase().includes(lowercasedFilter)
    );
    setFilteredDevices(filteredData);
  }, [filterText, poolDevices]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const handleAddDomainDevice = () => {
    deviceDialogMode.current = "add";
    setShowDialog(true);
  }

  const handleSelectAllDevices = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedDevices = new Set(poolDevices.map(device => device.device_id));
      setSelectedDevices(newSelectedDevices);
    } else {
      setSelectedDevices(new Set());
    }
  };

  const handleSelectDevice = (deviceId: string) => {
    console.log ("handleSelectDevice:", deviceId)
    const newSelectedDevices = new Set(selectedDevices);
    if (newSelectedDevices.has(deviceId)) {
      newSelectedDevices.delete(deviceId);
    } else {
      newSelectedDevices.add(deviceId);
    }
    setSelectedDevices(newSelectedDevices);
  };

  const handleDeleteSelectedDevices = () => {
    const devicesToDelete = Array.from(selectedDevices);
    const data = {
      "device_list" : devicesToDelete
    };
    DeviceService.deletePoolDevices(data, cookies.access_token).then((response: any) => {
      setSelectedDevices(new Set());
      setReloadDevices(reloadDevices + 1);
    });
  };

  const [availableDevices, setAvailableDevices] = useState<PoolDevice[]>([]);
  const [assignedDevices, setAssignedDevices] = useState<PoolDevice[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenAssignDialog = () => {
    setRegisterAndAssign (false);
    if (!nodeParent || !node) {
      return;
    }
    DeviceService.getPoolDevices(cookies.access_token, nodeParent.node_name).then((response: any) => {
      setAvailableDevices(response);
    });
    DeviceService.getPoolDevices(cookies.access_token, node.node_name).then((response: any) => {
      setAssignedDevices(response);
    });
    setOpenDialog(true);
  };


  const handleOpenRegisterAndAssignDialog = () => {
    setRegisterAndAssign (true);
    setShowDialog (true);
  }


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaveAssignments = (newAssignedDevices: PoolDevice[], ignoreRemove: boolean = false) => {
    //console.log ("handleSaveAssignments:", ignoreRemove, newAssignedDevices)
    const devicesToAdd = newAssignedDevices
      .filter(newDevice => !assignedDevices.some(existingDevice => existingDevice.device_id === newDevice.device_id))
      .map(device => device.device_id);

    const devicesToRemove = assignedDevices
      .filter(existingDevice => !newAssignedDevices.some(newDevice => newDevice.device_id === existingDevice.device_id))
      .map(device => device.device_id);

    setAssignedDevices(newAssignedDevices);

    const data = {
      parent_node: nodeParent?.node_name,
      node: node?.node_name,
      add: devicesToAdd,
      remove: ignoreRemove ? [] : devicesToRemove
    };
    //console.log ("UpdateDeviceAssignments:", data)
    DeviceService.updateDeviceAssignments(cookies.access_token, data).then((response: any) => {
      setOpenDialog(false);
      setReloadDevices(reloadDevices + 1);
    });
  };

  
  const assignDevices = (devices: string[]) => {
    //console.log ("assignDevices runs...", registerAndAssign, devices)

    // This should only run if we are auto-assigning the devices
    if (!registerAndAssign) {
      setReloadDevices (reloadDevices + 1)
      return;
    }
    // If the devices was added as a direct upload, call handleSaveAssignments

    // Retrieve the PoolDevice instances for the list of device IDs

    // access_token: string, node_name: string, device_list
    if (!nodeParent) {
      return;
    }
    DeviceService.getPoolDevices(cookies.access_token, nodeParent.node_name, devices).then((response: any) => {
      handleSaveAssignments (response, true)
    });
  } // assignDevices



  return (
    <div>
      <PoolDeviceDialog
        initialDevice={undefined}
        openState={showDialog}
        parentNode={node?.node_type === "domain" ? node : nodeParent}
        setOpenState={setShowDialog}
        dialogMode={deviceDialogMode.current}
        deviceTypes={node?.node_type === "domain" ? (node?.devicetypes || []) : (nodeParent?.devicetypes || [])}
        onDeviceAdded={assignDevices}
        maxAvailable={maxAvailableLicenses}
      />
      <AssignDeviceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveAssignments}
        availableDevices={availableDevices}
        assignedDevices={assignedDevices}
        licensePool={node?.licensePool || 0}
      />

      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label={t("type_to_filter")}
                variant="outlined"
                value={filterText}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            {selectedDevices.size > 0 && (
              <Grid item xs={6} container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<DeleteIcon />}
                  onClick={handleOpenWarningDialog}
                >
                  {t("delete_selected")}
                </Button>
              </Grid>
            )}
          </Grid>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  { node?.node_type === "customer" && <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedDevices.size > 0 && selectedDevices.size < poolDevices.length}
                      checked={poolDevices.length > 0 && selectedDevices.size === poolDevices.length}
                      onChange={handleSelectAllDevices}
                    />
                  </TableCell>}
                  <TableCell align="left">
                      Device ID
                  </TableCell>
                  <TableCell align="left">
                      Device Model
                  </TableCell>
                  <TableCell align="left">
                      Serial Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDevices.map((childNode: PoolDevice) => (
                  <TableRow
                    key={childNode.device_id}
                    onClick={ () => handleSelectDevice(childNode.device_id) }
                    style={{ cursor: 'pointer' }}
                  >
                    {  <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        checked={selectedDevices.has(childNode.device_id)}
                        onChange={() => handleSelectDevice(childNode.device_id)}
                      />
                    </TableCell>}
                    <TableCell align="left">{childNode.device_id || 'No description provided'}</TableCell>
                    <TableCell align="left">{childNode.deviceType}</TableCell>
                    <TableCell align="left">{childNode.device_serialnumber}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="body2" sx={{ marginTop: 2 }}>
            Total devices: {poolDevices.length} {filterText && `(Showing ${filteredDevices.length})`}
          </Typography>
        </CardContent>
      </Card>

      <Dialog
        open={showWarningDialog}
        onClose={handleCloseWarningDialog}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>Do you really want to delete the selected devices?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWarningDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="warning">Yes Delete</Button>
        </DialogActions>
      </Dialog>
      <Grid container style={{ marginTop: 8 }} justifyContent="flex-end">

        {node?.node_type === "domain" && showRegister && (
          <div>
            <Button variant="outlined"
              color="primary"
              sx={{ marginLeft: "5px" }}
              startIcon={<UploadIcon />}
              onClick={handleAddDomainDevice}
            >
              { t("register_domain_devices")}
            </Button>
          </div>
        )}
        {node?.node_type === "customer" && (
            <Box display="flex" justifyContent="space-between">
            { (maxAvailableLicenses > 0) && showRegister && <Button
              startIcon={<UploadIcon />}
              onClick={handleOpenRegisterAndAssignDialog}
              variant="outlined"
              color="primary"
            >
              { t("register_customer_devices")}
            </Button>
            }

            <Button
              variant="outlined"
              color="primary"
              sx={{ marginLeft: "5px" }}
              startIcon={<EditIcon />}
              onClick={handleOpenAssignDialog}
            >
              { t("assign_devices") }
            </Button>
          </Box>
        )}
      

      </Grid>
    </div>
  );
};

export default DeviceChildren;
