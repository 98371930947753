import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText } from '@mui/material';
//import { Device } from './MonitorGroupManager';
import { Device } from '../../interfaces/Device';

interface DeviceGroupsDialogProps {
    open: boolean;
    onClose: () => void;
    selectedDevice: Device | null;
    getDeviceGroups: (device: Device) => string[];
    handleGroupClickInDialog: (groupName: string) => void;
}

const DeviceGroupsDialog: React.FC<DeviceGroupsDialogProps> = ({ open, onClose, selectedDevice, getDeviceGroups, handleGroupClickInDialog }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Device Groups</DialogTitle>
            <DialogContent>
                {selectedDevice && (
                    <List>
                        {getDeviceGroups(selectedDevice).map(groupName => (
                            <ListItem key={groupName} button onClick={() => handleGroupClickInDialog(groupName)}>
                                <ListItemText primary={groupName} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeviceGroupsDialog;
