import { useContext, useEffect, useState, useRef } from "react";
import { AlarmsContext } from "../context/AlarmsContext";
import { NEWS } from "../interfaces/NEWS";
import { Device } from "../interfaces/Device";
import { PatientVitals } from "../interfaces/vitals/PatientVitals";
import { patientVitalsPlaceholder } from "../utils/data/PatientVitalsData";
import UpdateVitals from "../utils/functions/UpdateVitals";
import * as CONSTANTS from "../utils/constants/constants";
import { UserContext } from "../context/UserContext";
import { PatientInfo } from "../pages/Home/Live/LiveUnit";

interface Props {
    device: Device;
    data?: string;
    setDevices?: (devices: Device[]) => void;
}

const useVitalsSocket = ({ device, data, setDevices }: Props) => {
    const [vitals, setVitals] = useState<PatientVitals>(
        patientVitalsPlaceholder
    );
    const [news, setNews] = useState<NEWS | null>(null);
    const alarmsCtx = useContext(AlarmsContext);
    const userCtx = useContext(UserContext);
    const [patientInfo, setPatientInfo] = useState<PatientInfo | null>(null);
    const [interventionId, setInterventionId] = useState<string | null>(null);
    const [timestamp, setTimestamp] = useState<any>(null);
    const [gpsLocation, setGPSlocation] = useState<any>(null);
    const last_datagram_ts = useRef(0);
    const [connectStatus, setConnectStatus] = useState<string>("disconnected");
    const socketConnectStatus = useRef(false);

    const start_monitor_connectivity_check = (isMounted: boolean) => {
        if (isMounted) setTimeout(check_monitor_timeout, 3000, this);
        //console.log ("Start connectivity check ", device.device_id)
    };

    const check_monitor_timeout = (isMounted: boolean) => {
        let timer = setTimeout(check_monitor_timeout, 3000, this);

        let time_now = new Date().getTime();
        let diff = time_now - last_datagram_ts.current;
        //console.log ("check timeout:", device.device_id, last_datagram_ts.current, diff)

        if (diff > CONSTANTS.CONNECTIVITY_TIMEOUT) {
            if (socketConnectStatus.current && isMounted) {
                //console.log ("Disconnected", device.device_id)
                setConnectStatus("disconnected");
                socketConnectStatus.current = false;
            }
        }
        if (!isMounted) clearTimeout(timer);
    };

    const strobe_connectivity = (isMounted: boolean) => {
        //console.log ("Strobe:", device.device_id)
        if (!socketConnectStatus.current && isMounted) {
            //console.log ("Connected:", device.device_id)
            setConnectStatus("connected");
            socketConnectStatus.current = true;
        }
        last_datagram_ts.current = new Date().getTime();
    };



    const sortScores = () => {
        //console.log ("sorting", setDevices)
        setDevices &&
            //@ts-ignore
            setDevices((prevDevices: Device[]) => {
                return prevDevices.sort(
                    (a, b) =>
                        Number(
                            b.noData
                                ? b.noData
                                : true > a.noData!
                                ? a.noData
                                : true
                        ) *
                            2 -
                        1
                );
            });
    };

    useEffect(() => {
        let isMounted = true;
        const socket = new WebSocket (CONSTANTS.WS_HOST() + "ws/vicu-socket/" + userCtx?.user?.organization + "/" + device.device_id + "/");

        socket.onmessage = (event) => {
            try {
                if (event.data !== "") {
                    const ts = JSON.parse(event.data).message.timestamp;
                    const intervention_id = JSON.parse(event.data).message.intervention_id;

                    if (isMounted) {
                        setTimestamp(ts);
                        setInterventionId(intervention_id);
                    }
                    strobe_connectivity(isMounted);
                    //setStaleData(false);
                    sortScores();

                    UpdateVitals(
                        event,
                        setVitals,
                        setNews,
                        alarmsCtx?.setAlarms,
                        setPatientInfo
                    );

                    const gps = JSON.parse(event.data)?.message.gps_location;
                    if (gps && gps.lat && gps.lng) {
                        if (!isNaN(gps.lat) && !isNaN(gps.lng)) {
                            setGPSlocation(gps);
                        }
                    }
                } else {
                    //setStaleData(true);
                    if (isMounted) sortScores();
                }
            } catch (err: any) {
                console.error(err);
            }
        };

        socket.onopen = () => {
            // Send WS message about what trend data we want
            const streamRequest = {
                type: "server-request",
                opcode: data ?? "stream_parameters",
                organization: userCtx?.user?.organization,
                device_id: device.device_id,
            };

            socket.send(JSON.stringify(streamRequest));
            start_monitor_connectivity_check(isMounted);
        };

        return () => {
            console.log("Closing socket");
            socket.close();
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device.device_id, data]);

    return {
        vitals,
        news,
        patientInfo,
        gpsLocation,
        connectStatus,
        timestamp,
        setDevices,
        interventionId
    };
};

export default useVitalsSocket;
