
import React, { useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography }from '@mui/material';
import versionData from '../version.json';
import { isPharlap } from '../utils/constants/constants';
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";

interface AboutDialogProps {
    open: boolean;
    onClose: () => void;
  }

  

const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose }) => {
    var sysType: String = "SentioWeb Standard";
    const { t } = useTranslation();
    const userCtx = useContext(UserContext);

    //console.log ("Build number:", process.env.REACT_APP_BUILD_NUMBER, process.env.REACT_APP_BUILD_DATE)
    if (isPharlap) {
        sysType = "Pharlap";
    }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="about-dialog-title"
    >
      <DialogTitle id="about-dialog-title">{t("about_system")}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("system_type")} : {sysType}
        </Typography>
        <Typography>
          {t("software_variant")} : {userCtx?.user?.sentio_variant}
        </Typography>
        <Typography>
          {t("system_version")} : {versionData.softwareVersion}
        </Typography>
        <Typography>
          {t("build_date")} : {process.env.REACT_APP_BUILD_DATE}
        </Typography>
        <Typography>
          {t("build_number")} : {process.env.REACT_APP_BUILD_NUMBER}
        </Typography>

      </DialogContent>
      {/* Optionally add DialogActions here for any buttons */}
    </Dialog>
  );
};

export default AboutDialog;
