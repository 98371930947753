import React from 'react';
import { useDrop } from "react-dnd";
import { Grid, Button, useTheme } from "@mui/material";
import { useCookies } from "react-cookie";
import { VICU } from "../../interfaces/VICU";
import VICUService from "../../services/VICUService";

interface VicuTabBarProps {
    vicuList: VICU[];
    activateVicu: any;
    activeVicu?: VICU | null;
    refreshDevices: any;
}

export const VicuTabBar = ({
    vicuList,
    activateVicu,
    activeVicu,
    refreshDevices,
}: VicuTabBarProps) => {
    const theme = useTheme();

    return (
        <>
            {/* VICU list */}
            <Grid container direction="row" sx={{ paddingBottom: 1 }}>
                {vicuList?.map((vicu: VICU) => (
                    <VicuTab
                        key={vicu.group_id}
                        vicu={vicu}
                        activateVicu={activateVicu}
                        active={activeVicu === vicu}
                        activeGroup={activeVicu}
                        refreshDevices={refreshDevices}
                        theme={theme}
                    />
                ))}
            </Grid>
        </>
    );
};

interface VicuTabProps {
    vicu: VICU;
    activateVicu: any;
    active: boolean;
    activeGroup?: VICU | null;
    refreshDevices: any;
    theme: any;
}

export const VicuTab = ({ vicu, activateVicu, active, activeGroup, refreshDevices, theme }: VicuTabProps) => {
    const [cookies, ,] = useCookies(["access_token"]);
    const selectVICU = (v: VICU) => {
        activateVicu(v);
    };

    const [{ isOver }, drop] = useDrop({
        accept: 'ITEM',
        drop: (item: any) => {
            if (vicu.group_name === "syspool") {
                VICUService.manageGroupDevices(
                    // @ts-ignore
                    activeGroup.group_id,
                    { type: "detach", devices: [{ "device_id": item.id }] },
                    cookies.access_token
                )
                    .then((res: any) => {
                        // Handle success
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
                return;
            }

            VICUService.manageGroupDevices(
                // @ts-ignore
                vicu.group_id,
                { type: "attach", devices: [{ "device_id": item.id }] },
                cookies.access_token
            )
                .then((res: any) => {
                    // Handle success
                })
                .catch((err: any) => {
                    console.log(err);
                });
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} style={{ backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.paper }}>
            <Button
                variant={active ? "contained" : "outlined"}
                sx={{
                    marginRight: 1,
                    color: active
                        ? theme.palette.mode === 'dark'
                            ? theme.palette.text.primary
                            : theme.palette.common.white
                        : vicu.vicu_type === "pool"
                            ? "darkorange"
                            : theme.palette.text.primary,
                    backgroundColor: active ? (theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main) : undefined,
                    borderColor: theme.palette.divider,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    }
                }}
                onClick={(event: any) => selectVICU(vicu)}
            >
                {vicu.group_name}
            </Button>
        </div>
    );
};
