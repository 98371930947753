import React from 'react';
import { Paper, Typography, InputBase, List, Divider } from '@mui/material';
import { useDrop } from 'react-dnd';
import DeviceItem from './DeviceItem';
import { Device } from "../../interfaces/Device"
import { useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

interface AvailableDevicesProps {
    devices: Device[];
    searchTerm: string;
    setSearchTerm: (term: string) => void;
    handleRemoveFromGroup: (device: Device) => void;
    handleDeviceClick: (device: Device) => void;
}

const AvailableDevices: React.FC<AvailableDevicesProps> = ({ devices, searchTerm, setSearchTerm, handleRemoveFromGroup, handleDeviceClick }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'DEVICE',
        drop: (item: Device) => {
            handleRemoveFromGroup(item);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    const filteredAndSortedDevices = devices
        .filter(device => 
            (device.nickname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.device_id.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        .sort((a, b) => {
            const nameA = a.nickname || a.device_id;
            const nameB = b.nickname || b.device_id;
            return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
        });

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                backgroundColor: isOver ? theme.palette.action.hover : theme.palette.background.paper,
                color: theme.palette.text.primary,
            }}
            ref={drop}
        >
            <Typography variant="h6">{ t("available_devices") }</Typography>
            <InputBase
                placeholder={ t("search_devices") }
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                    mb: 2,
                    width: '100%',
                    padding: '4px',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '4px',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                }}
            />
            <Divider sx={{ my: 1 }} /> {/* Add the Divider here */}
            <List>
                {filteredAndSortedDevices.map((device) => (
                    <DeviceItem key={device.device_id} device={device} onClick={() => handleDeviceClick(device)} />
                ))}
            </List>
        </Paper>
    );
};

export default AvailableDevices;
