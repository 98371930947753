import React, { useEffect, useRef } from "react";

interface WebRTCPlayerProps {
    video_server: string;
    camera_id: string;
    width?: string | number; // Width can be a number (for pixels) or a string (for percentages, etc.)
    height?: string | number; // Height can be a number (for pixels) or a string (for percentages, etc.)
}
/*
    Usage Example
        <Grid>
            <WebRTCPlayer video_server = "https://video.sentioweb.com"
                    camera_id = "S702627"
                    width={640} height={480}
            />

        </Grid>
*/


const WebRTCPlayer: React.FC<WebRTCPlayerProps> = ({ width, height, camera_id, video_server }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const src = video_server + "/stream/" + camera_id + "/channel/" + camera_id + "/webrtc"

  useEffect(() => {
    const startPlay = (videoEl: HTMLVideoElement, url: string) => {
      const webrtc = new RTCPeerConnection({
        iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
      });
      webrtc.ontrack = (event) => {
        console.log(event.streams.length + " track is delivered");
        videoEl.srcObject = event.streams[0];
        videoEl.play();
      };
      webrtc.addTransceiver("video", { direction: "sendrecv" });
      webrtc.onnegotiationneeded = async () => {
        const offer = await webrtc.createOffer();
        await webrtc.setLocalDescription(offer);

        fetch(url, {
          method: "POST",
          body: new URLSearchParams({
            data: btoa(webrtc.localDescription!.sdp),
          }),
        })
          .then((response) => response.text())
          .then((data) => {
            try {
              webrtc.setRemoteDescription(
                new RTCSessionDescription({ type: "answer", sdp: atob(data) })
              );
            } catch (e) {
              console.warn(e);
            }
          });
      };

      const webrtcSendChannel = webrtc.createDataChannel(
        "rtsptowebSendChannel"
      );
      webrtcSendChannel.onopen = () => {
        console.log(`${webrtcSendChannel.label} has opened`);
        webrtcSendChannel.send("ping");
      };
      webrtcSendChannel.onclose = () => {
        console.log(`${webrtcSendChannel.label} has closed`);
        startPlay(videoEl, url);
      };
      webrtcSendChannel.onmessage = (event) => console.log(event.data);
    };

    if (videoRef.current) {
      startPlay(videoRef.current, src);
    }
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    // Prepare the video styles
    const videoStyles = {
        maxWidth  : width ? width : "100%", // Use provided width or fallback to 100%
        maxHeight : height ? height : "100%", // Use provided height or fallback to 100%
    };

  return (
    <video
      ref={videoRef}
      autoPlay
      muted
      playsInline
      controls
      style={ videoStyles }
    ></video>
  );
};

export default WebRTCPlayer;
