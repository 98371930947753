import { useState, useEffect } from "react";
import {
    useTheme,
    Grid,
    Typography,
    Button,
    Dialog,
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCookies } from "react-cookie";
import UserService from "../../services/UserService";
import { isBrowser } from "react-device-detect";
import UserAccessDialog from "../UserMgmt/UserAccessDialog";
import { GroupType } from "../UserMgmt/UserAccessDialog";
import VICUService from "../../services/VICUService";
import {  User } from "../../interfaces/user/User";


  
export const AccessManagement = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cookies, ,] = useCookies(["access_token"]);
    //const [errorMsg, setErrorMsg] = useState(""); // Show error message dialog
    const [showOK, setShowOK] = useState(false); // Show OK dialog on user add success
    //const [userName, setUserName] = useState<string>("");
    //const [fetchUsers, setFetchUsers] = useState(0);

    var token = cookies.access_token; // userCtx.user.token;
    var [users, setUsers] = useState<User[]>([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
    const [filterText, setFilterText] = useState('');

    const handleRowClick = (username: any) => {
      setSelectedUsername(username);
      setIsDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
      setSelectedUsername(null);
    };
  


    const sortUsersByLastName = (users: User[]) => {
        return [...users].sort((a, b) => {
          // Handle cases where last_name could be undefined
          const lastNameA = a.last_name || ""; // Fallback to empty string if undefined
          const lastNameB = b.last_name || ""; // Fallback to empty string if undefined
          return lastNameA.localeCompare(lastNameB, undefined, { sensitivity: 'base' });
        });
      };

      
    // Fetch all users I can see in my sub-organizations
    useEffect(() => {
        UserService.getAllHierarchyUsers(token).then((response: any) => {
            //console.log ("Fetch all hierarhcy users response:", response)

            // Sort the users by last name
            const sortedUSers = sortUsersByLastName (response.users)
            setUsers (sortedUSers)
        })
        .catch((error: any) => {
            console.log("Fetch error response:", error);
        })
        .finally(() => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const updateGroupAssignments = (username: string, groups: GroupType[]) => {
        const extractedData = groups.map(({ vicu_id, assigned }) => ({ vicu_id, assigned }));

        //console.log ("update group access:", extractedData)
        
        VICUService.manageGroupAssignments (username, extractedData, token).then((res: any) => {
            //console.log("manage group assignments result:", res);
        }).catch ((error: any) => {
            console.log("Error updateGroupAssignments:", error);

            //setErrorMsg(error);
        })
        .finally(() => {
            //console.log ("Completed createUser")
        });
    }

    

    const handleCloseOK = (
        event: React.SyntheticEvent<unknown>,
        reason?: string
    ) => {
        if (reason !== "backdropClick") {
            setShowOK(false);
        }
    };


     const fontVariant = isBrowser ? "body2" : "caption";
    //console.log ("user:", user)
    //console.log ("userLevels:", userLevels)
    //console.log ("selectedUserLevel:", selectedUserLevel)
    return (
        <Box sx={{ mx: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={filterText}
                        placeholder={t("type_to_filter")}
                        onChange={(e) => setFilterText(e.target.value)}
                        sx={{ marginY: 1 }} // Spacing below the text field
                    />
                </Grid>
                <Grid item xs>
                    <Typography variant="h4" align="center">
                        { t("access_management") }
                    </Typography>
                </Grid>
            </Grid>
    
            {/* SUCCESS DIALOG */}
            <Dialog open={showOK} fullWidth={true} maxWidth={"md"}>
                <DialogTitle> {t("success")}</DialogTitle>
                <DialogContent>
                    <Typography>{t("user_added_successfully")}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseOK}> {t("close")}</Button>
                </DialogActions>
            </Dialog>
    
            <Grid item xs={12} md={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                    
                                        {t("lastname")}
                                    
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px", minWidth: 70 }}>
                                        {t("firstname")}
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                        {t("username")}
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                        {t("email_address")}
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                        {t("phone")}
                                </TableCell>
                                <TableCell sx={{ padding: "16px 6px" }}>
                                        {t("role")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.filter((user) => {
                                const searchText = filterText.toLowerCase();
                                return (
                                    user.last_name?.toLowerCase().includes(searchText) ||
                                    user.first_name?.toLowerCase().includes(searchText) ||
                                    user.username?.toLowerCase().includes(searchText) ||
                                    user.email?.toLowerCase().includes(searchText) ||
                                    user.phone?.toLowerCase().includes(searchText) ||
                                    user.organization_name?.toLowerCase().includes(searchText) ||
                                    (user.is_admin ? 'Yes' : 'No').toLowerCase().includes(searchText)
                                );
                            }).map((user: User) => (
                                <TableRow
                                    key={user.username}
                                    hover
                                    onClick={() => handleRowClick(user.username)}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                        "&:nth-of-type(odd)": {
                                            background: theme.palette.info.dark,
                                        },
                                    }}
                                >
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.last_name}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.first_name}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.username}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.email}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{user.phone}</Typography>
                                    </TableCell>
                                    <TableCell sx={{ padding: "6px 6px" }}>
                                        <Typography variant={fontVariant}>{t(user.role?.name || '')}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
    
            {selectedUsername && (
                <UserAccessDialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    username={selectedUsername}
                    onOk={(updatedGroups: GroupType[]) => {
                        updateGroupAssignments(selectedUsername, updatedGroups);
                    }}
                />
            )}
        </Box>
    );
    
}; 