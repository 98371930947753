import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HEARTBEAT_GREEN_COLOR, HEARTBEAT_YELLOW_COLOR, HEARTBEAT_GREY_COLOR } from './MissionCentral';

const Legend = () => {
  const { t } = useTranslation();

  const dotStyle = (color: string) => ({
    display: 'inline-block',
    width: '1em',
    height: '1em',
    borderRadius: '50%',
    backgroundColor: color,
    marginRight: '0.5em',
  });

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="body2">
        <span style={dotStyle(HEARTBEAT_GREEN_COLOR)}></span>{t('live')}
        <span style={{ ...dotStyle(HEARTBEAT_YELLOW_COLOR), marginLeft: '1em' }}></span>{t('last_10_minutes')}
        <span style={{ ...dotStyle(HEARTBEAT_GREY_COLOR), marginLeft: '1em' }}></span>{t('last_24_hours')}
        <span style={{ ...dotStyle('transparent'), border: '1px solid grey', marginLeft: '1em' }}></span>{t('no_data')}
      </Typography>
    </Box>
  );
};

export default Legend;
